import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button/button';
import './modal.scss';
import PropTypes from 'prop-types';

class PopupModal extends React.Component {
    constructor(props, context) {
        super(props, context);
    }


    render() {
        let { } = this.props
        return (
            <Modal
                size="lg sm xs"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                className="customModal"

            >
                <a className='icon-close cursor icon-css' onClick={this.props.onClose}></a>
                <Modal.Body>
                    <h4 className='paddingB15'>Next Session</h4>
                    <h5 className='paddingB15'>When would you like to set your next reminder ?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>set reminder</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}



export default PopupModal;

