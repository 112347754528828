import React, { useState } from 'react';
import { setLocalActiveClass, getStoredData, setStoredData } from "../../utils/helper";
import './ViewReport.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../components/homesidebar/homesidebar'
import HomeHeader from '../../components/homeheader/homeheader';
import { Row, Col } from 'react-bootstrap';
import TextField from '../../components/textfield/textfield';
import Button from "../../components/Button/button";
import CONSTANTS from '../../utils/constants';
import ToastComponent from "../../components/toastcomponent/toastcomponent";
import { getFormDataObject } from '../../utils/helper';
import WebService from "../../utils/Webservice"
import AdminSideMenu from '../../components/adminsidebar/adminsidebar';
import AdminHeader from '../../components/adminheader/adminheader';
import Overuse from '../../assets/images/overuse.svg';
import moment from 'moment';
import { create } from 'istanbul-reports';

class ViewReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            profession: '',
            _id: (this.props.history.location.state.isViewFrom === 'adminViewReport') ? getStoredData("userId") : getStoredData("_id"),
            isViewFrom: this.props.history.location.state.isViewFrom,
            imagePreviewUrl: '',
            isEdit: 'false',
            email: {
                value: "",
                isValid: true,
                message: ""
            },
            sFullName: {
                value: "",
                isValid: true,
                message: ""
            },
            sPhoneNumber: {
                value: "",
                isValid: true,
                message: ""
            },
            errorMessage: '',
            isToastShow: false,
            showPass: false,
            sucessData: false,
            message: '',
            overUseReport: []
        }
        this.state.isViewFrom === 'homeViewReport' ? setLocalActiveClass("ViewReport") : setLocalActiveClass("ViewReport")
    }

    componentDidMount() {
        this.getReportSignOfOverUse()
    }

    async getReportSignOfOverUse() {
        try {
            let obj = {
                iUserId: this.state._id,
            };
            let response = await WebService.getReportOverUse(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                this.setState({ overUseReport: response.data })
            } else {
                this.handleErrorResponse(response);
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    append(value1, value2) {
        return Array.isArray(value1) ? value1.length > 0 ? (value1 + ", " + value2) : value2 : ''

    }
    getValuesFromReport(value) {
        let reason = value?.aReport.length !== 0 ?
            value.aReport.length === 2 ?
                value.aReport[0]['Please tell us what kind of pain or discomfort you felt.'] === undefined ?
                    '' :
                    value.aReport[0]['Please tell us what kind of pain or discomfort you felt.'].toString()
                : (value.aReport[1] === undefined || !'Please tell us what kind of pain or discomfort you felt.' in value.aReport[1] ? '' :
                    'Please tell us what kind of pain or discomfort you felt.' in value.aReport[0] ?
                        value.aReport[0]['Please tell us what kind of pain or discomfort you felt.'].toString() : '')
            : ''
        let commentsData = value.aReport.length !== 0 ? value.aReport.length === 2 ? value.aReport[1]['Additional Comments'] : value.aReport[2] === undefined ? '' : value.aReport[2]['Additional Comments'] : ''
        return { reason, commentsData }
    }
    render() {
        let { profession, treatment, isViewFrom, overUseReport, adminEmail, adminPhoneNumber, imagePreviewUrl, fileToaster, sucessData, adminFirstName, adminLastName, errorMessage, isToastShow, file, toaster, message, isShow, isWarning } = this.state
        let overUsedArray = overUseReport.filter((value, index, ar) => {
            return value.isOveruse
        })
        let createdAt = []

        overUsedArray.map((value, index) => {
            let date = moment(value.dCreatedAt).format("DD MMM,YYYY")
            const found = createdAt.some((el, index) => el.date === date);
            if (!found) {
                let reason = this.getValuesFromReport(value).reason
                let commentsData = this.getValuesFromReport(value).commentsData
                createdAt.push({ date, reason, commentsData, aReport: value.aReport });
            } else {
                let index = createdAt.findIndex(item => item.date === date);
                let foundObje = createdAt[index]
                let reason = this.getValuesFromReport(value).reason
                let commentsData = this.getValuesFromReport(value).commentsData
                createdAt[index].reason = this.append(foundObje.reason, reason)
                createdAt[index].commentsData = this.append(foundObje.commentsData, commentsData)
            }
        })

        return (
            <div className='main-ViewReport' >
                {isViewFrom === 'adminViewReport' && <AdminHeader  {...this.props} ></AdminHeader>}
                {isViewFrom === 'homeViewReport' ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <AdminSideMenu {...this.props} showMorePopup={true}></AdminSideMenu>}
                {isViewFrom != 'adminViewReport' && <HomeHeader  {...this.props} ></HomeHeader>}

                <div className='inner-Profile'>
                    <Scrollbars className='home-Scroll'>
                        <div className='main-Container'>
                            <div className='img-Container'>
                                <img src={Overuse} className="logo" alt="logo" />
                            </div>
                            <h2 className='paddingB15'>Signs of overuse</h2>
                            <Scrollbars className='home-Scroll'></Scrollbars>
                            {createdAt.length !== 0 ?
                                <div className='viewReport'>
                                    <ul className='headUl'>
                                        <li className='heading'>Sr no </li>
                                        <li className='heading'>Date </li>
                                        <li className='heading'>Pain or Discomfort </li>
                                        <li className='heading'>Comments </li>
                                    </ul>
                                    {
                                        createdAt.map((value, index) => {
                                            return (
                                                < ul className='dataUl'>
                                                    <li className='heading'><span className='spanLi'>{index + 1}</span></li>
                                                    <li className='heading'><span className='spanLi'> {value.date}</span> </li>

                                                    <li className='heading'><span className='spanLi'>{value.reason}</span></li>
                                                    <li className='heading'><span className='spanLi'> {value.commentsData} </span></li>
                                                </ul>
                                            )
                                        })
                                    }
                                </div>
                                : "No Data Found"
                            }
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default ViewReport;

