import React from 'react';
import './overlay.scss';
import PropTypes from 'prop-types';

class Overlay extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        let { } = this.props
        return (
            <div className='overlay-Main'>
                <div className='inner-Overlay'>
                    <p className='overlay-Text'>Please complete onboarding first on your smartphone with the Oralift App.</p>
                    <p className='overlay-Text'>You can download the App on the <a href="https://apps.apple.com/us/app/oralift/id1496482821" target="_blank" className='appLink'>App store</a> and <a href="https://play.google.com/store/apps/details?id=com.oralift.oraliftapp&hl=en_IN" target="_blank" className='appLink'>Play store.</a></p>
                    <p className='overlay-Text'>Onboarding can be done ONLY via the Oralift App on your smartphone.</p>
                    <p className='overlay-Text'>Once you have completed onboarding , click <a href="/" onClick={() => { this.props.onItemClick() }} target="_blank" className='appLink'>here</a> to continue.</p>

                </div>
            </div>
        )
    }
}




export default Overlay;

