import React, { useState } from "react";
import { useEffect } from "react";
import Tab from "react-bootstrap/Tab";

import closeButtontext from "../../../assets/images/close-icon.svg";
import moment from "moment";
import WebService from "../../../utils/Webservice";
import { object } from "prop-types";
import Swal from "sweetalert2";
import "react-tooltip/dist/react-tooltip.css";
import { FcOk } from "react-icons/fc";
import { formatDate, secondsToHhMmSs } from "../../../utils/helper";
let Calender = (props) => {
  let [iUserId, setIUserId] = useState();
  let [componentData, setComponentData] = useState({
    firstDay: moment().startOf("month").format("d"),
    lastDay: moment().endOf("month").format("d"),
  });
  console.log("componentData", componentData);
  let [previousMonthDate, setPreviousMonthDate] = useState({});
  let [currentMonthDate, setCurrentMonthDate] = useState({});
  let [nextMonthDate, setNextMonthDate] = useState({});
  let [progressData, setProgressData] = useState([]);
  let [firstMonth, setFirstMonth] = useState("");
  let [currentMonth, setCurrentMonth] = useState({});
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var date = moment();
  const [monthYear, setMonthYear] = useState(date);
  function showAlert(dataObj) {
    console.log(dataObj, "****Aniii****");
    const date = formatDate(dataObj._id);
    const customClass = "custom-swal-popup";
    const buttonclass = "closeIcons";
    if (
      Object.keys(dataObj.am).length > 0 &&
      Object.keys(dataObj.pm).length === 0
    ) {
      const timeformat_3mm = secondsToHhMmSs(dataObj.am.threeMmTime);
      const timeformat_5mm = secondsToHhMmSs(dataObj.am.fiveMmTime);
      Swal.fire({
        title: `<div style="font-size: 20px !important; color: #5D045D !important;">${date} - AM</div>`,
        html: `
        <div style="margin-bottom:20px; text-align:left;">
        <label style="font-weight: bold;font-size: 16px;color: #000000;display: block;">Device:3mm</label>
        <label style="font-weight: bold;font-size: 16px;color: #000000;display: block;">Time Worn:  ${timeformat_3mm}</label>
        </div>
        
        <div style="margin-bottom:20px; text-align:left;">
        <label style="font-weight: bold;font-size: 16px;color: #000000;display: block;">Device:5mm</label>
        <label style="font-weight: bold;font-size: 16px;color: #000000;display: block;">Time Worn:  ${timeformat_5mm}</label>
      </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          popup: customClass,
          title: customClass,
          htmlContainer: customClass,
          closeButton: buttonclass,
        },
        didOpen: () => {
          // Apply custom styles to the modal when it opens
          const customStyles = `
            .${customClass} {
              max-width: 290px; 
              border-radius: 10px;
            }
            .${buttonclass} { 
              background: #CF4891 !important;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              font-size: 27px;
              color: #fff;
              position: absolute;
              right: 10px;
              top: 6px;
            }
          `;
          // Add custom styles to the head of the document
          const styleElement = document.createElement("style");
          styleElement.innerHTML = customStyles;
          document.head.appendChild(styleElement);
        },
      });
    } else if (Object.keys(dataObj.pm).length > 0) {
      const timeformat_3mm = secondsToHhMmSs(dataObj.pm.threeMmTime);
      const timeformat_5mm = secondsToHhMmSs(dataObj.pm.fiveMmTime);
      Swal.fire({
        title: `<div style="font-size: 20px !important; color: #5D045D !important;">${date} - PM</div>`,
        html: `
        <div style="margin-bottom:20px; text-align:left;">
        <label style="font-weight: bold;font-size: 16px;color: #000000;display: block;">Device:3mm</label>
        <label style="font-weight: bold;font-size: 16px;color: #000000;display: block;">Time Worn: ${timeformat_3mm}</label>
        </div>

        <div style="margin-bottom:20px; text-align:left;">
        <label style="font-weight: bold;font-size: 16px;color: #000000;display: block;">Device:5mm</label>
        <label style="font-weight: bold;font-size: 16px;color: #000000;display: block;">Time Worn: ${timeformat_5mm}</label>
      </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          popup: customClass,
          title: customClass,
          htmlContainer: customClass,
          closeButton: buttonclass,
        },
        didOpen: () => {
          // Apply custom styles to the modal when it opens
          const customStyles = `
            .${customClass} {
              max-width: 290px; 
              border-radius: 10px;
            }
            .${buttonclass} { 
              background: #CF4891 !important;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              font-size: 27px;
              color: #fff;
              position: absolute;
              right: 10px;
              top: 6px;
            }
          `;
          // Add custom styles to the head of the document
          const styleElement = document.createElement("style");
          styleElement.innerHTML = customStyles;
          document.head.appendChild(styleElement);
        },
      });
    } else {
      Swal.fire({
        title: `<div style="font-size: 20px !important; color: #5D045D !important;">${date}</div>`,
        html: `<label>No Data Found!<label>`,
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
          popup: customClass,
          title: customClass,
          htmlContainer: customClass,
          closeButton: buttonclass,
        },
        didOpen: () => {
          // Apply custom styles to the modal when it opens
          const customStyles = `
            .${customClass} {
              max-width: 290px; 
              border-radius: 10px;
            }
            .${buttonclass} { 
              background: #CF4891 !important;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              font-size: 27px;
              color: #fff;
              position: absolute;
              right: 10px;
              top: 6px;
            }
          `;

          // Add custom styles to the head of the document
          const styleElement = document.createElement("style");
          styleElement.innerHTML = customStyles;
          document.head.appendChild(styleElement);
        },
      });
    }
  }
  function getPrevMonthDays(year, month, day) {
    let index = day;
    const dates = {};
    // console.log(day, "indexxxxxxxxxx");
    while (index > 0) {
      let dateObj = moment(monthYear).startOf("month");
      dateObj.subtract(index, "days");
      dates[dateObj.format("YYYY-MM-DD")] = dateObj.format("D");
      index--;
    }
    console.log("datesPrevious", dates);
    return dates;
  }
  function getAllDaysInMonth(year, month) {
    let dateObj = moment(monthYear).startOf("month");
    const dates = {};
    while (dateObj.format("M") - 1 == month) {
      dates[dateObj.format("YYYY-MM-DD")] = dateObj.format("D");
      dateObj.add(1, "days");
    }
    return dates;
  }
  function getNextMonthDays(year, month, day) {
    let dateObj = moment().endOf("month");
    let index = 1;
    const dates = {};
    // console.log(day, "indexxxxxxxxxx");
    while (index <= day) {
      dateObj.add(1, "days");
      dates[dateObj.format("YYYY-MM-DD")] = dateObj.format("D");
      index++;
    }
    return dates;
  }
  useEffect(() => {
    console.log(
      'monthYear.format("YYYY-MM-DD")',
      monthYear.format("YYYY-MM-DD")
    );
    const now = new Date(monthYear.format("YYYY-MM-DD"));
    // 👇️ all days of the current month
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    console.log("now.getFullYear()>>>>>>", componentData.firstDay);
    setCurrentMonth({ currentMonth: month[monthYear.format("MMMM")] });
    let previousMonthObject = getPrevMonthDays(
      now.getFullYear(),
      now.getMonth(),
      componentData.firstDay
    );
    let currentMonthObject = getAllDaysInMonth(
      now.getFullYear(),
      now.getMonth()
    );
    let nextMonthObject = getNextMonthDays(
      now.getFullYear(),
      now.getMonth(),
      7 - componentData.lastDay - 1
    );
    setPreviousMonthDate(previousMonthObject);
    setCurrentMonthDate(currentMonthObject);
    setNextMonthDate(nextMonthObject);
  }, [monthYear]);

  useEffect(() => {
    let responseProgressDataCalender = WebService.getProgressDataCalender(
      props,
      monthYear.format("YYYY-MM-DD")
    ).then((data) => {
      // console.log("dataaaaaaaaaaaaaaaaaaaaa", data);
      setProgressData(data.data);
      setFirstMonth(data.registeredMonth);
    });
  }, []);
  // console.log("previousMonthDate", previousMonthDate);
  // console.log("currentMonthDate", currentMonthDate);
  // console.log("nextMonthDate", nextMonthDate);
  // console.log("progressData", progressData);
  const addMonth = () => {
    let newNextMonth = moment(monthYear);
    let currentMonth =
      moment().add(1, "M").format("MMMM : YYYY") ===
      newNextMonth.add(1, "M").format("MMMM : YYYY");
    if (!currentMonth) {
      let responseProgressDataCalender = WebService.getProgressDataCalender(
        props,
        newNextMonth.startOf("month").format("YYYY-MM-DD"),
        newNextMonth.startOf("month").format("YYYY-MM-DD")
      ).then((data) => {
        // console.log("dataaaaaaaaaaaaaaaaaaaaa", data);
        setProgressData(data.data);
      });
      console.log(
        'nextMonth.format("YYYY-MM-DD")',
        newNextMonth.format("YYYY-MM-DD")
      );
      setComponentData({
        firstDay: newNextMonth.startOf("month").format("d"),
        lastDay: newNextMonth.endOf("month").format("d"),
      });
      setMonthYear(newNextMonth);
    }
  };
  const subtractMonth = () => {
    // let newPreviousMonth = moment(monthYear);
    // setMonthYear(newPreviousMonth.subtract(1, 'M'));
    let newNextMonth = moment(monthYear);
    let currentMonth =
      moment().add(1, "M").format("MMMM : YYYY") ===
      newNextMonth.subtract(1, "M").format("MMMM : YYYY");
    if (!currentMonth) {
      let responseProgressDataCalender = WebService.getProgressDataCalender(
        props,
        newNextMonth.startOf("month").format("YYYY-MM-DD"),
        newNextMonth.startOf("month").format("YYYY-MM-DD")
      ).then((data) => {
        // console.log("dataaaaaaaaaaaaaaaaaaaaa", data);
        setProgressData(data.data);
      });
      console.log(
        'nextMonth.format("YYYY-MM-DD")',
        newNextMonth.format("YYYY-MM-DD")
      );
      setComponentData({
        firstDay: newNextMonth.startOf("month").format("d"),
        lastDay: newNextMonth.endOf("month").format("d"),
      });
      setMonthYear(newNextMonth);
    }
  };
  let newNextMonth = moment(monthYear);
  return (
    <section className="container">
      <div className="table-control-nav">
        <button
          disabled={firstMonth === newNextMonth.format("MMMM : YYYY")}
          onClick={subtractMonth}
          className="pre_btn me-1"
        >
          &nbsp;
        </button>
        <p className="tbl-caption"> {monthYear.format("MMMM : YYYY")}</p>
        <button
          disabled={
            moment().add(1, "M").format("MMMM : YYYY") ===
            newNextMonth.add(1, "M").format("MMMM : YYYY")
          }
          onClick={addMonth}
          className="next_btn"
        >
          &nbsp;
        </button>
      </div>
      <table id="calendar">
        <tr className="weekdays">
          <th scope="col">Sun</th>
          <th scope="col">Mon</th>
          <th scope="col">Tue</th>
          <th scope="col">Wed</th>
          <th scope="col">Thu</th>
          <th scope="col">Fri</th>
          <th scope="col">Sat</th>
        </tr>

        <tr className="days">
          {Object.entries(previousMonthDate).map((key) => (
            <td className="day other-month" key={key[0]}>
              <div className="date">{key[1]}</div>
            </td>
          ))}
          {Object.entries(currentMonthDate).map((key) => (
            <td className="day" key={key[0]}>
              <div className="date">{key[1]}</div>
              <>
                {progressData.length &&
                  progressData.map((object) => {
                    // console.log('objecttttttttttttttttt', key[0])
                    return object._id == key[0] ? (
                      <>
                        {object._id === key[0] ? (
                          <div
                            onClick={() => showAlert(object)}
                            className="boxwith_times firstbx"
                          >
                            <span className="titlesubb">AM</span>
                            <span className="datesubb">{key[1]}</span>

                            {/* <span> {key[1]}</span> */}
                            <div className="timeBox">
                              <div className="manageHalf">
                                <div className="touchesBox">AM</div>
                                {Object.keys(object?.am).length > 0 ? (
                                  <div className="touchesBox">
                                    <FcOk size={24} />
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="boxwith_times_no_data firstbx">
                            <span className="titlesubb">AM</span>
                            <div className="timeBox">
                              <div className="manageHalf"></div>
                            </div>
                          </div>
                        )}

                        {object._id === key[0] ? (
                          <div
                            onClick={() => showAlert(object)}
                            className="boxwith_times"
                          >
                            <span className="titlesubb">PM</span>
                            <div className="timeBox">
                              <div className="manageHalf">
                                <div className="touchesBox">PM</div>
                                {Object.keys(object?.pm).length > 0 ? (
                                  <div className="touchesBox">
                                    <FcOk size={24} />
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="boxwith_times_no_data firstbx">
                            <span className="titlesubb">PM</span>
                            <div className="timeBox">
                              <div className="manageHalf"></div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    );
                  })}
              </>
            </td>
          ))}
          {Object.entries(nextMonthDate).map((key, value) => {
            return (
              <td className="day other-month" key={key[0]}>
                <div className="date">{key[1]}</div>
              </td>
            );
          })}
        </tr>
      </table>

      <div className="legendsInMobile"></div>
      <footer>
        <p className="footer-box">Click Green box for info</p>
        <p className="footer-text">
          The info provided will be the device and time worn of last recorded
          session on that day.
        </p>
      </footer>
    </section>
  );
};
export default Calender;
