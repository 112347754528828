import React, { useState } from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './forgotpassword.scss';
import { Scrollbars } from "react-custom-scrollbars";
import WebService from "../../../utils/Webservice"
import Button from "../../../components/Button/button";
import TextField from '../../../components/textfield/textfield';
import CONSTANTS from '../../../utils/constants';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginForm: true,
            forgotForm: false,
            email: {
                value: "",
                isValid: false,
                message: ""
            },

            forgetEmail: {
                value: "",
                isValid: false,
                message: ""
            },
            errorMessage: '',
            isToastShow: false,
            showPass: false
        }


    }
    onSendLink() {
        // console.log(this.state.username)
        this.validateFields('email', this.state.email.value)
        this.forgotPasswordWebServices()
    }
    onKeyDown(e) {
        e.keyCode == 13 && this.forgotPasswordWebServices()
    }

    handleUserInput = e => {
        // console.log("aaaaa,,",e.target.name, e.target.value)

        let name = e.target.name;
        let value = e.target.value;

        var obj = {};
        obj[name] = {
            value: value
        };
        this.setState({
            showEye: name == "password" && value != "" ? true : false
        });

        this.setState(obj, () => {
            this.validateField(name, value);
        });
    };
    // text on change //
    validateField(fieldName, value) {
        let valid = false;
        let message = "";

        switch (fieldName) {
            case "email":
                valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
                !valid &&
                    (message =
                        value.length <= 0
                            ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH
                            : CONSTANTS.MESSAGES.VALIDATION.EMAIL);
                break;

            // case "forgetEmail":
            //     valid = value.match(CONSTANTS.REGEX.EMAIL);
            //     !valid && (message = CONSTANTS.MESSAGES.VALIDATION.EMAIL);
            //     break;
            // default:
            //     break;
        }
        var obj = {};
        obj[fieldName] = {
            message: message,
            isValid: valid,
            value: value
        };
        this.setState(obj, () => {
            fieldName == "forgetEmail"
                ? this.validateForgotForm()
                : this.validateForm();
        });
    }
    // validation on Button//
    validateFields(fieldName, value) {
        let valid = false;
        let message = "";

        switch (fieldName) {
            case "email":
                valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
                !valid &&
                    (message =
                        value.length <= 0
                            ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH
                            : CONSTANTS.MESSAGES.VALIDATION.EMAIL);
                break;

            // case "forgetEmail":
            //     valid = value.match(CONSTANTS.REGEX.EMAIL);
            //     !valid && (message = CONSTANTS.MESSAGES.VALIDATION.EMAIL);
            //     break;
            // default:
            //     break;
        }
        var obj = {};
        // obj[fieldName] = {
        //     message: message,
        //     isValid: valid,
        //     value: value
        // };
        // toast message show //
        if (!valid) {
            this.setState({
                isToastShow: true,
                errorMessage: message
            })
        }

        this.setState(obj, () => {
            fieldName == "forgetEmail"
                ? this.validateForgotForm()
                : this.validateForm();
        });
    }
    validateForm() {
        this.setState({
            isFormValid: this.state.email.isValid
        });
    }

    //*****************************Forgot Password Validation*******************
    validateForgotForm() {
        this.setState({
            isForgotValid: this.state.forgetEmail.isValid
        });
    }
    onForgotPassword = () => {
        this.setState({
            loginForm: false,
            forgotForm: true,
            showTopBar: false,
            animated: true
        });
    };

    async  forgotPasswordWebServices() {
        try {
            let obj = {
                sEmailId: this.state.email.value,
            };
            let response = await WebService.adminForgotPassword(obj);
            this.setState({ isLoading: false });
            if (response.status) {
                // console.log("Success::" + response.status);
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: false })

            } else {
                // this.handleErrorResponse(response);
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: true })
                // console.log("Error::" + response.status);
            }
        } catch (error) {
            // console.log(error);
        }
    }



    render() {
        let { disabled, toaster, isToastShow, errorMessage, isWarning } = this.state
        return (
            <div className='main-ForgotPassword'>
                {toaster && <ToastComponent isWarning={isWarning} message={errorMessage} show={isToastShow} onClose={() => { this.setState({ toaster: false }); }} delay={3000} autohide ></ToastComponent>}

                <div className='inner-ForgotPassword'>
                    <Scrollbars className='home-Scroll'>
                        <div className='main-Container'>
                            <div className='img-upload'>
                                <img src={require('../../../assets/images/forgot.png')} className='profileimg' />
                            </div>
                            <h4>Forgot Password</h4>
                            <h5>Don’t worry! all you need to do is enter your registered email address and a reset link will be sent to you. </h5>
                            <div className='form'>
                                <TextField title="Email"
                                    value={this.state.email.value}
                                    autoFocus={true}
                                    name="email"
                                    iconClass={"icon-mail"}
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.email.message}
                                    onKeyDown={this.onKeyDown.bind(this)}
                                    placeholder="johndoe@gmail.com" >
                                </TextField>

                                <Button title="Send Link"
                                    disabled={!this.state.isFormValid} onClick={() => this.onSendLink()}></Button>
                                <a href='/admin' className='aLink'>Back to Login </a>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default ForgotPassword;

