import React, { useState } from 'react';
import { setLocalActiveClass } from "../../../utils/helper";
import './instructions.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import HomeHeader from '../../../components/homeheader/homeheader';
import { Row, Col } from 'react-bootstrap';
import VideoPlayer from '../../../components/videoplayer/videoplayer'
import handleViewport from 'react-in-viewport';
class Instructions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            top: 0
        }
        setLocalActiveClass("HomeInstructions")
    }

    onReadMore3mmClick() {
        this.props.history.push('/3mmfittingstep1', { isViewFrom: 'home3mm' })
    }
    onReadMore5mmClick() {
        this.props.history.push('/5mmfittingstep1', { isViewFrom: 'home5mm' })
    }
    onTouchTestClick() {
        this.props.history.push('/touchtest', { isViewFrom: 'homeTouchTest' })
    }
    onFreeWayClick() {
        this.props.history.push('/freewayspace', { isViewFrom: 'homeFreewayspace' })
    }
    onGuide2PhotoClick() {
        this.props.history.push('/addphotoguide', { isViewFrom: 'homeAddphotoguide' })
    }
    handleUpdate(values) {
        const { top } = values;
        this.setState({ top }, () => {
            //  console.log(this.state.top);
        });
    }
    render() {
        return (
            <div className='main-Instructions'>

                <HomeSideMenu {...this.props} showPopup={true}></HomeSideMenu>
                <HomeHeader {...this.props}></HomeHeader>
                <div className='inner-Instructions'>
                    <Scrollbars onUpdate={this.handleUpdate.bind(this)} className='home-Scroll'>
                        <div className='main-Container'>
                            <div className="more_info_main">
                                <span className='moreinfotitle'>More Info</span>
                                <div className="tooltip_main">
                                    <div className={this.state.infoView ? 'divclass show ' : 'divclass'} onClick={() => this.setState({ infoView: !this.state.infoView })}></div>
                                    <div className={this.state.infoView ? 'tool-Tips show' : 'tool-Tips'} >
                                        <a class="tip icon-info cursor" onClick={() => this.setState({ infoView: !this.state.infoView })}>
                                            <span className="info-detail">If you want to watch any of the videos, there are available on this tab. The rules for calculating your wear time and when you should change from 3mm to 5mm are also available here. There are also 2 sections explaining the signs of overuse and how to monitor the changes that take place on your face.
                                            </span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div className='image-Section paddingB40'>
                                <div className='left-Side width50'>
                                    <div className='img-Container'>
                                        <img src={require('../../../assets/images/3mm.png')} />
                                    </div>
                                    <div className='main-Section'>
                                        <div className='content-Section paddingT15 paddingB40'>
                                            <h4 className='colorP paddingB15'>3mm Fitting - Fitting Guide</h4>
                                            <h5 className='paddingB15'>To fit you 3mm device, you should follow the same procedure as you did with 5mm device and if you wish, you can watch the series of fitting videos…</h5>
                                            <a onClick={() => this.onReadMore3mmClick()} className='aLink'>Read More</a>
                                        </div>
                                        <div className='content-Section'>
                                            <h4 className='colorS paddingB15 minHeight'>Touch Test Introduction</h4>
                                            <h5 className='paddingB15'>Now that you have fitted your 5mm device, it is time to do the Touch Test. Please watch the Touch Test video.</h5>
                                            <a onClick={() => this.onTouchTestClick()} className='aLink paddingB15'>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='right-Side width50'>
                                    <div className='img-Container'>
                                        <img src={require('../../../assets/images/5mm.png')} />
                                    </div>
                                    <div className='main-Section '>
                                        <div className='content-Section paddingT15 paddingB40'>
                                            <h4 className='colorS paddingB15 '>5mm Fitting - Fitting Guide</h4>
                                            <h5 className='paddingB15'>Fitting your Oralift device is easy. Please follow the steps in the instruction video below: You may prefer to watch all videos before start…</h5>
                                            <a onClick={() => this.onReadMore5mmClick()} className='aLink'>Read More</a>
                                        </div>
                                        <div className='content-Section'>
                                            <h4 className='colorS paddingB15 minHeight'>Freeway Space</h4>
                                            <h5 className='paddingB15'>One of the key elements to the success or Oralift is understanding the Freeway Space. The video below will help you understand this and you…</h5>
                                            <a onClick={() => this.onFreeWayClick()} className='aLink'>Read More</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='main-Video'>
                                <h2 className='paddingB15'>Guide to taking photos</h2>
                                <VideoPlayer playing={this.state.top >= 0.6656686626746507 ? true : false} width={940} url={"https://vimeo.com/454706049/121564531d"} controls={true}>
                                </VideoPlayer>
                                <a onClick={() => this.onGuide2PhotoClick()} className='aLink paddingB15'>Read More</a>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default Instructions;

