import CONSTANTS from "./constants";

export const setLocalActiveClass = (page) => {
  localStorage.setItem("menuActiveitem", page);
  localStorage.setItem("MainActiveClass", "");
};
export const setFilledFlags = (key, flag) => {
  localStorage.setItem(key, JSON.stringify(flag));
};

export const twoDigitNumber = (myNumber) => {
  return ("0" + myNumber).slice(-2);
};
export const checkForHistoryParam = (location, paramName) => {
  if (location != undefined) {
    return location[paramName];
  } else {
    return false;
  }
};

export const checkForHistoryPushParam = (location, paramName) => {
  if (location != undefined) {
    return location[paramName];
  } else {
    return localStorage.getItem(paramName);
  }
};

export const getFormDataObject = (params) => {
  let data = new FormData();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const element = params[key];
      data.append(key, element);
    }
  }
  return data;
};
export const setStoredData = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.log("error", error);
  }
};

export function getStoredData(key) {
  try {
    let data = localStorage.getItem(key);
    return data;
  } catch (error) {
    console.log("error", error);
  }
}

export function formatDate(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = inputDate.split("-");
  const year = dateParts[0];
  const month = months[parseInt(dateParts[1], 10) - 1];
  const day = parseInt(dateParts[2], 10);

  return `${day} ${month} ${year}`;
}

// export function secondsToHhMmSs(seconds) {
//   var hours = Math.floor(seconds / 3600);
//   var minutes = Math.floor((seconds % 3600) / 60);
//   var remainingSeconds = Math.floor(seconds % 60);

//   var formattedTime = `${String(hours).padStart(2, "0")}:${String(
//     minutes
//   ).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;

//   return formattedTime;
// }

export function secondsToHhMmSs(inputTime) {
  // Parse input time in mm.ss format
  const [minutes, seconds] = inputTime.toString().split(".").map(parseFloat);

  // Check if seconds is NaN, and set it to 0 if necessary
  const formattedSeconds = isNaN(seconds) ? 0 : Math.round(seconds);

  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);

  // Format the result
  const formattedTime = [
    hours.toString().padStart(2, "0"),
    remainingMinutes.toString().padStart(2, "0"),
    formattedSeconds.toString().padStart(2, "0"),
  ];

  return formattedTime.join(":");
}
