/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  setLocalActiveClass,
  getStoredData,
  setStoredData,
} from "../../../utils/helper";
import "./progress.scss";
import { Scrollbars } from "react-custom-scrollbars";
import SideMenu from "../../../components/sidebar/sidemenu";
import HomeSideMenu from "../../../components/homesidebar/homesidebar";
import AdminSideMenu from "../../../components/adminsidebar/adminsidebar";
import HomeHeader from "../../../components/homeheader/homeheader";
import AdminHeader from "../../../components/adminheader/adminheader";
import Highchart from "../../../components/Highchart/Highchart";
import DropdownMenu from "../../../components/dropdown/dropdown";
import ProgressWeekly from "../../../components/progressweekly/progressweekly";
import WebService from "../../../utils/Webservice";
import Calender from "./Calender";
import { Row, Col } from "react-bootstrap";
import "./calender.css";
import threemmDeviceImg from "../../../assets/imageforcalender/3_mm_picture_for_legend.png";
import fivemmDeviceImg from "../../../assets/imageforcalender/5mm_for_legend.png";
import cameraImg from "../../../assets/imageforcalender/cameraIcon.png";
import { el } from "date-fns/locale";
// import ReactTooltip from 'react-tooltip';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
var _ = require("lodash");
var moment = require("moment");
var moment_timezone = require("moment-timezone");
const dataLength = 7;
const optionsProgress = ["Weekly", "Monthly", "2 Monthly"];
const machine = ["3 mm", "5 mm"];
Array.range = function (a, b, step) {
  var A = [];
  if (typeof a == "number") {
    A[0] = a;
    step = step || 1;
    while (a + step <= b) {
      A[A.length] = a += step;
    }
  } else {
    var s = "abcdefghijklmnopqrstuvwxyz";
    if (a === a.toUpperCase()) {
      b = b.toUpperCase();
      s = s.toUpperCase();
    }
    s = s.substring(s.indexOf(a), s.indexOf(b) + 1);
    A = s.split("");
  }
  return A;
};
class Homeprogress extends React.Component {
  constructor(props) {
    const default_timezone =
      localStorage.getItem("timezone") || "Europe/London";
    console.log("default_timezone:::____", default_timezone); // Output: "default_timezone!"
    super(props);

    console.log("propssssss", this.props.match.params.token);
    this.state = {
      showToast: true,
      default_timezone: default_timezone,
      setModalShow: false,
      modalShow: false,
      isWeeksTxt: "",
      isyearslected: "",
      isWeeks: "0",
      arrayResDate: [],
      weeks: [],
      months: [],
      monthly: [],
      iUserId:
        this.props.history.location.state === undefined &&
        this.props.match.params._id != undefined
          ? this.props.match.params._id
          : this.props.match?.params?.token
          ? this.props.match.params.token
          : this.props.history.location.state?.isViewFrom === "adminDashboard"
          ? getStoredData("userId")
          : getStoredData("_id"),
      isMachineType: 0,
      machineTxt: "",
      machineType: 0,
      isMachineTxt: "",
      allDates: [],
      weekStartDate: "",
      isViewFrom:
        this.props.history.location.state === undefined &&
        this.props.match.params._id === undefined
          ? "adminDashboard"
          : this.props.history.location.state?.isViewFrom,
      dataWeekWise: [],
      amData: [],
      pmData: [],
      graphDateArray: [],
      endIndex: 7,
      amGraphData: [],
      pmGraphData: [],
      monthwiseDates: [],
      isTouchTestDue: getStoredData("isTouchTestDue"),
      isQuestionnaireDue: getStoredData("isQuestionnaireDue"),
      //  defaultSelWeeks: ''
    };

    console.log("this.state.iUser", this.state.iUserId);
    this.props.history.location.state === undefined &&
    this.props.match.params._id === undefined
      ? setLocalActiveClass("Progress")
      : this.props.history.location.state?.isViewFrom === "home" ||
        this.props.history.location.state?.isViewFrom === "homeSidemenu"
      ? setLocalActiveClass("HomeProgress")
      : setLocalActiveClass("Progress");
  }
  onGoClick() {
    this.props.history.push("/setreminder");
  }
  onReadMore3mmClick() {
    // //this.props.history.push('/3mmguide')
    // this.setState({ isManualEntry: true })
  }

  onManualClick() {
    this.props.history.push("/questionbiweekly");
  }
  showToast() {
    this.setState({
      show: true,
    });
    setTimeout(() => {
      this.setState({
        show: false,
      });
    }, 3000);
  }
  setModalShow = (show) => {
    this.setState({
      modalShow: show,
    });
  };
  handleSelect(eventKey, event) {
    this.setState({ isWeeks: eventKey });
  }
  handleSelectyear(e) {
    this.setState({ isyearslected: e.target.text }, () =>
      this.onYearChangeGetMonth(this.state.isyearslected, this.state.value)
    );
  }
  onyearchangeGetTwoMonthaly(year) {
    let multiMonths = [];

    let yyyy = year;

    this.state.amData.map((item) => {
      if (String(new Date(item._id).getFullYear()) === String(yyyy)) {
        let monthString = moment()
          .month(new Date(item._id).getMonth())
          .format("MMM");
        // //console.log("monthString::::", monthString)
        multiMonths.push(this.getMultiMonths(monthString));
      }
    });

    if (multiMonths.length > 0) {
      const distinct = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      multiMonths = multiMonths.filter(distinct);
    }
    this.setState(
      {
        selMultiMonth: multiMonths[multiMonths.length - 1],
        monthly: multiMonths,
      },
      () => this.onClickTwoMonthData(this.state.selMultiMonth)
    );
  }
  onYearChangeGetMonth(year, isweek) {
    if (Number(isweek) === 1) {
      let yyyy = year;
      let graphMonthIndex = [];
      this.state.amData.map((item) => {
        if (String(new Date(item._id).getFullYear()) === String(yyyy)) {
          let monthString = moment()
            .month(new Date(item._id).getMonth())
            .format("MMM");
          if (!graphMonthIndex.includes(monthString))
            graphMonthIndex.push(this.getMonths(monthString));
        }
      });
      this.setState(
        {
          selMonth: graphMonthIndex[graphMonthIndex.length - 1],
          months: graphMonthIndex,
        },
        () => this.onCickMonthData(graphMonthIndex[graphMonthIndex.length - 1])
      );
    } else {
      this.onyearchangeGetTwoMonthaly(this.state.isyearslected);
    }
  }
  onItemHandleEvent(e, option, eventKey) {
    this.setState(
      {
        isWeeksTxt: e.target.text,
        selOption: option,
        value: eventKey,
        isWeeks: eventKey,
      },
      () => {
        switch (eventKey) {
          case "0":
            let { weeks } = this.state;
            let lastWeek = weeks[weeks.length - 1];
            this.setState(
              {
                selectedWeek: lastWeek,
              },
              () => {
                this.filterWeeksData(lastWeek);
              }
            );
            break;
          case "1":
            this.onYearChangeGetMonth(this.state.isyearslected, 1);
            // let { months } = this.state;
            // let lastMonth = months[months.length - 1];
            // this.setState(
            //   {
            //     selectedMonth: lastMonth,
            //   },
            //   () => {
            //     this.filterMonthsData(lastMonth);
            //   }
            // );
            break;
          case "2":
            this.onyearchangeGetTwoMonthaly(this.state.isyearslected);
            // let { monthly } = this.state;
            // let lastMonthly = monthly[monthly.length - 1];
            // this.setState(
            //   {
            //     selectedMultiMonth: lastMonthly,
            //   },
            //   () => {
            //     this.filterMultiMonthsData(lastMonthly);
            //   }
            // );
            break;
          case "3":
            let lastMonthy = this.state.months[this.state.months.length - 1];

            this.setState(
              {
                selectedMonth: lastMonthy,
              },
              () => {
                this.filterMonthsData(lastMonthy);
              }
            );
            break;
          default:
            break;
        }
      }
    );
  }
  handleSelectMachine(eventKey) {
    this.setState({ isMachineType: eventKey });
  }
  onHandlEvent(e, option, eventKey) {
    this.setState(
      {
        isMachineTxt: e.target.text,
        selOption: option,
        value: eventKey,
        isMachineType: eventKey,
      },
      () => {
        this.progressDataWebServices();
      }
    );
  }
  handleMachineType(eventKey) {
    this.setState({ machineType: eventKey });
  }
  onHandleMachineType(e, option, eventKey) {
    this.setState({
      machineTxt: e.target.text,
      selOption: option,
      value: eventKey,
      machineType: eventKey,
    });
  }
  componentDidMount() {
    this.progressDataWebServices();
    this.getCalenderDates();
  }
  async progressDataWebServices() {
    try {
      let obj = {
        iUserId: this.state.iUserId,
        nDeviceType: this.state.isMachineType,
      };
      let responseProgressData = await WebService.getProgressData(
        obj,
        this.props.match?.params?.token
      );
      let responseTouchTestData = await WebService.getTouchTestData(
        obj,
        this.props.match?.params?.token
      );
      console.log("responseProgressDataaaaaaaaa", responseProgressData);
      this.setState({ isLoading: false });
      if (responseProgressData.status) {
        responseProgressData.data.AM.map((amRes) => {
          amRes.dCreatedAt = moment_timezone
            .utc(amRes.dCreatedAt)
            .tz(amRes.tz || this.state.default_timezone);
          amRes._id = amRes.dCreatedAt;
          // console.log("amRes:modufy _id date::", amRes)
        });
        responseProgressData.data.PM.map((pmRes) => {
          pmRes.dCreatedAt = moment_timezone
            .utc(pmRes.dCreatedAt)
            .tz(pmRes.tz || this.state.default_timezone);
          pmRes._id = pmRes.dCreatedAt;
          // console.log("pmRes:modufy _id date::", pmRes)
        });
        this.handleSuccessResponse(
          responseProgressData.data,
          responseTouchTestData.data
        );
      } else {
        this.setState({
          graphDate: [],
          amGraphData: [],
          pmGraphData: [],
          graphTouchTestDate: [],
          graphTouchTestData: [],
          isDropdownDisabled: false,
        });
      }
    } catch (error) {
      console.log("catch :responseProgressData::---", error);
    }
  }

  async handleSuccessResponse(response, responseTouchTest) {
    //console.log("response****************", response)
    let amData = response.AM;
    let pmData = response.PM;
    let touchTestData = responseTouchTest;
    let responseDate = [];
    let weeks = [];
    if (amData.length <= 0) {
      return;
    }
    let firstDate = null;
    let lastDate = null;
    const count = amData.length;
    amData.map((item, index) => {
      item._id = moment_timezone
        .utc(item.dCreatedAt)
        .tz(item.tz || this.state.default_timezone)
        .format("YYYY-MM-DD");
      // console.log("AM data map item._id::", item._id)
      /**
       * @comment converted date with spefic timezone
       */
      const timezone = item?.tz || this.state.default_timezone;
      item.dCreatedAt = this.convertedDateWithTimezone(
        timezone,
        item.dCreatedAt
      );
      if (index === 0) {
        // firstDate = moment(item["dCreatedAt"]).format("YYYY-MM-DD");
        firstDate = moment_timezone
          .utc(item.dCreatedAt)
          .tz(item.tz || this.state.default_timezone)
          .format("YYYY-MM-DD");
      } else if (index === count - 1) {
        // lastDate = moment(item["dCreatedAt"]).format("YYYY-MM-DD");
        lastDate = moment_timezone
          .utc(item.dCreatedAt)
          .tz(item.tz || this.state.default_timezone)
          .format("YYYY-MM-DD");
      }
      if (count === 1) {
        // lastDate = moment(item["dCreatedAt"]).format("YYYY-MM-DD");
        lastDate = moment_timezone
          .utc(item.dCreatedAt)
          .tz(item.tz || this.state.default_timezone)
          .format("YYYY-MM-DD");
      }
    });
    if (firstDate && lastDate) {
      weeks = this.getWeeks(firstDate, lastDate);
    }
    this.setState({ weeks });
    amData.map((index) => {
      // console.log("AM Response set", index)
      responseDate.push(index._id);
    });
    let graphDateArray = [];
    amData.map((obj) => {
      // console.log("AM Obj:", amData)
      obj["y"] = obj["total"];
      obj["photos"].length === 0
        ? (obj["isPhotos"] = false)
        : (obj["isPhotos"] = true);
    });
    pmData.map((obj) => {
      // obj._id = moment(obj.dCreatedAt).format("YYYY-MM-DD");
      obj._id = moment_timezone
        .utc(obj.dCreatedAt)
        .tz(obj.tz || this.state.default_timezone)
        .format("YYYY-MM-DD");
      obj["y"] = obj["total"];
      obj["photos"].length === 0
        ? (obj["isPhotos"] = false)
        : (obj["isPhotos"] = true);
      /**
       * @comment converted date with speficx timezone
       */
      const timezone = obj?.tz || this.state.default_timezone;
      obj.dCreatedAt = this.convertedDateWithTimezone(timezone, obj.dCreatedAt);
    });
    touchTestData.map((obj) => {
      obj["y"] = obj["total"];
      obj["photos"].length === 0
        ? (obj["isPhotos"] = false)
        : (obj["isPhotos"] = true);
    });
    weeks = this.state.weeks;
    let lastWeek = weeks[weeks.length - 1];
    // console.log("amData", amData);
    // console.log("pmData", pmData);

    this.setState(
      {
        selectedWeek: lastWeek,
        amData: amData,
        pmData: pmData,
        touchTestData: touchTestData,
      },
      () => {
        this.filterWeeksData(lastWeek);
      }
    );

    // *****************************************************************Month******************************************************************************

    // amData.map((item) => {
    //   if (
    //     moment(new Date(item._id)).format("yyyy") ===
    //     moment(new Date()).format("yyyy")
    //   ) {
    //     let monthString = moment()
    //       .month(new Date(item._id).getMonth())
    //       .format("MMM");
    //     if (!graphMonthIndex.includes(monthString))
    //       graphMonthIndex.push(this.getMonths(monthString));
    //   }
    // });
    // this.setState({
    //   selMonth: graphMonthIndex[graphMonthIndex.length - 1],
    //   months: graphMonthIndex,
    // });

    /**********************************************2Monthly*********************************************************************************** */
    // this.onyearchangeGetTwoMonthaly(this.state.isyearslected);
    // let multiMonths = [];
    // amData.map((item) => {
    //   let monthString = moment()
    //     .month(new Date(item._id).getMonth())
    //     .format("MMM");
    //   multiMonths.push(this.getMultiMonths(monthString));
    // });

    // if (multiMonths.length > 0) {
    //   const distinct = (value, index, self) => {
    //     return self.indexOf(value) === index;
    //   };
    //   multiMonths = multiMonths.filter(distinct);
    // }
    // this.setState({
    //   selMultiMonth: multiMonths[multiMonths.length - 1],
    //   monthly: multiMonths,
    // });
  }
  onClickTwoMonthData(item) {
    this.setState(
      {
        selectedMultiMonth: item,
      },
      () => {
        this.filterMultiMonthsData(this.state.selectedMultiMonth);
      }
    );
  }
  compareMultiMonths = (data, month) => {
    if (data === undefined) {
      return;
    }
    if (data.length <= 0) {
      return;
    }
    let months = month.split("-");
    return data.filter((item) => {
      let monthString = moment(item["_id"]).format("MMM-YYYY");
      return (
        monthString === `${months[0]}-${this.state.isyearslected}` ||
        monthString === `${months[1]}-${this.state.isyearslected}`
      );
    });
  };

  filterMultiMonthsData = (filter) => {
    let AM = this.compareMultiMonths(this.state.amData, filter);
    let PM = this.compareMultiMonths(this.state.pmData, filter);
    let touchTestData = this.compareMultiMonths(
      this.state.touchTestData,
      filter
    );
    if (AM === undefined) {
      return;
    }
    if (AM.length <= 0) {
      return;
    }
    let arrayDate = [];
    AM.map((amItem) => {
      console.log("amItem::", amItem);
      // arrayDate.push(moment_timezone.utc(amItem._id).tz(amItem.tz || this.state.default_timezone).format("YYYY-MM-DD"));
      // arrayDate.push(moment(new Date(amItem["_id"])).format("MMM DD,YYYY"));
      arrayDate.push(amItem._id);
    });
    // added for time tome

    // arrayDate Modify ********************
    arrayDate = [...new Set(arrayDate)];
    console.log("arrayDate**********for graph", arrayDate);

    // AM Data modify
    const resultMapAM = {};
    AM.forEach((item) => {
      if (!resultMapAM[item._id]) {
        resultMapAM[item._id] = { ...item };
      } else {
        resultMapAM[item._id].total += item.total;
        resultMapAM[item._id].y += item.total;
      }
    });
    AM = Object.values(resultMapAM);

    // PM Data modify
    const resultMapPM = {};
    PM.forEach((item) => {
      if (!resultMapPM[item._id]) {
        resultMapPM[item._id] = { ...item };
      } else {
        resultMapPM[item._id].total += item.total;
        resultMapPM[item._id].y += item.total;
      }
    });
    PM = Object.values(resultMapPM);

    // Touchtest Modify
    const touchtesObj = {};
    touchTestData.forEach((item) => {
      if (!touchtesObj[item._id]) {
        touchtesObj[item._id] = { ...item };
      } else {
        touchtesObj[item._id].total += item.total;
        touchtesObj[item._id].y += item.total;
      }
    });
    touchTestData = Object.values(touchtesObj);

    this.setState({
      selMultiMonth: filter,
      graphDate: arrayDate,
      amGraphData: AM,
      pmGraphData: PM,
      graphTouchTestDate: arrayDate,
      graphTouchTestData: touchTestData,
    });
  };

  onWeeksClick(item) {
    this.setState(
      {
        selectedWeek: item,
      },
      () => {
        this.filterWeeksData(item);
      }
    );
  }
  compareWeeks = (data, startDate) => {
    var startDate = moment(startDate);
    // console.log("startDate:::",startDate)
    startDate = startDate.startOf("week").toString();
    if (data === undefined) {
      return;
    }
    if (data.length <= 0) {
      return;
    }
    return data.filter((item) => {
      let dateString = moment(item["_id"]).startOf("week").toString();
      return dateString === startDate;
    });
  };

  filterWeeksData = (filter) => {
    let AM = this.compareWeeks(this.state.amData, filter);
    let PM = this.compareWeeks(this.state.pmData, filter);
    let touchTest = this.compareWeeks(this.state.touchTestData, filter);
    let arrayDate = [];
    if (AM === undefined) {
      return;
    }
    if (PM === undefined) {
      return;
    }
    console.log("AM::::length", AM);
    console.log("PM::::length", PM);
    AM.map((amItem) => {
      console.log("amItem, 2::--", amItem);
      // arrayDate.push(moment_timezone.utc(amItem._id).tz(amItem.tz || this.state.default_timezone).format("YYYY-MM-DD"));
      // arrayDate.push(moment(new Date(amItem["_id"])).format("MMM DD,YYYY"));
      arrayDate.push(amItem._id);
    });
    // PM.map((pmItem) => {
    //   arrayDate.push(moment(new Date(pmItem["_id"])).format("MMM DD,YYYY"));
    // });
    if (AM.length <= 0) {
      return;
    }
    console.log("arrayDate::-", arrayDate);
    this.setState({
      clickIndex: filter,
      amGraphData: AM,
      pmGraphData: PM,
      graphDate: arrayDate,
      graphTouchTestData: touchTest,
      graphTouchTestDate: arrayDate,
    });
  };

  getWeeks(firstDate, lastDate) {
    this.setState({
      yearlyoption: Array.range(
        Number(firstDate.split("-")[0]),
        Number(lastDate.split("-")[0]),
        1
      ).reverse(),
      isyearslected: Number(lastDate.split("-")[0]),
    });
    var firstDate = new Date(firstDate);
    var first = firstDate.getDate() - firstDate.getDay() + 1;
    var firstday = new Date(firstDate.setDate(first)).toUTCString();

    var lastDate = new Date(lastDate);
    var last = lastDate.getDate() - lastDate.getDay() + 7;
    var lastday = new Date(lastDate.setDate(last)).toUTCString();

    let date1 = new Date(firstday);
    let date2 = new Date(lastday);

    let weekStartDate = [firstday];

    for (let i = 0; i < this.numberfWeeks(date1, date2) - 1; i++) {
      let initStartDate = firstDate.setTime(
        firstDate.getTime() + 7 * 24 * 60 * 60 * 1000
      );
      let startDate = new Date(initStartDate).toUTCString();
      weekStartDate.push(startDate);
    }
    return weekStartDate;
  }

  getDates(startDate, endDate) {
    var dates = [];
    startDate = startDate.add(1, "days");
    while (startDate.format("YYYY-MM-DD") !== endDate.format("YYYY-MM-DD")) {
      dates.push(moment(startDate.toDate()).format("YYYY-MM-DD"));
      startDate = startDate.add(1, "days");
    }
    return dates;
  }
  numberfWeeks = (dt2, dt1) => {
    // return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    return Math.abs(Math.round(diff));
  };
  compareMonths = (data, month) => {
    let mm = month + "-" + this.state.isyearslected;
    if (data.length <= 0) {
      return;
    }
    return data.filter((item) => {
      let monthString = moment(item["_id"]).format("MMM-YYYY");
      return monthString === mm;
    });
  };
  filterMonthsData = (filter) => {
    let AM = this.compareMonths(this.state.amData, filter);
    let PM = this.compareMonths(this.state.pmData, filter);
    let arrayDate = [];
    if (AM === undefined) {
      return;
    }
    AM.map((amItem) => {
      console.log("ammLdate:: 3", amItem._id);
      // arrayDate.push(moment_timezone.utc(amItem._id).tz(amItem.tz || this.state.default_timezone).format("YYYY-MM-DD"));
      // arrayDate.push(moment(new Date(amItem["_id"])).format("MMM DD,YYYY"));
      arrayDate.push(amItem._id);
    });
    if (AM.length <= 0) {
      return;
    }
    // added for time tome

    // arrayDate Modify ********************
    arrayDate = [...new Set(arrayDate)];
    console.log("arrayDate**********for graph", arrayDate);

    // AM Data modify
    const resultMapAM = {};
    AM.forEach((item) => {
      if (!resultMapAM[item._id]) {
        resultMapAM[item._id] = { ...item };
      } else {
        resultMapAM[item._id].total += item.total;
        resultMapAM[item._id].y += item.total;
      }
    });
    AM = Object.values(resultMapAM);

    // PM Data modify
    const resultMapPM = {};
    PM.forEach((item) => {
      if (!resultMapPM[item._id]) {
        resultMapPM[item._id] = { ...item };
      } else {
        resultMapPM[item._id].total += item.total;
        resultMapPM[item._id].y += item.total;
      }
    });
    PM = Object.values(resultMapPM);

    //console.log("arrayDate**** 2", arrayDate)
    let touchTest = this.compareMonths(this.state.touchTestData, filter);
    // added for time tome

    // Touchtest Modify
    const touchtesObj = {};
    touchTest.forEach((item) => {
      if (!touchtesObj[item._id]) {
        touchtesObj[item._id] = { ...item };
      } else {
        touchtesObj[item._id].total += item.total;
        touchtesObj[item._id].y += item.total;
      }
    });
    touchTest = Object.values(touchtesObj);

    this.setState({
      selMonth: filter,
      amGraphData: AM,
      pmGraphData: PM,
      graphDate: arrayDate,
      graphTouchTestData: touchTest,
      graphTouchTestDate: arrayDate,
    });
  };
  onCickMonthData(i) {
    this.setState(
      {
        selectedMonth: i,
      },
      () => {
        this.filterMonthsData(i);
      }
    );
  }

  getMultiMonths = (monthString) => {
    switch (monthString.toLowerCase()) {
      case "jan":
      case "feb": {
        return "Jan-Feb";
      }
      case "mar":
      case "apr": {
        return "Mar-Apr";
      }
      case "may":
      case "jun": {
        return "May-Jun";
      }
      case "jul":
      case "aug": {
        return "Jul-Aug";
      }
      case "sep":
      case "oct": {
        return "Sep-Oct";
      }
      case "nov":
      case "dec": {
        return "Nov-Dec";
      }
      default: {
        return "Jan";
      }
    }
  };

  getMonths = (monthString) => {
    switch (monthString.toLowerCase()) {
      case "jan":
        return "Jan";

      case "feb":
        return "Feb";
      case "mar":
        return "Mar";

      case "apr":
        return "Apr";
      case "may":
        return "May";

      case "jun":
        return "Jun";
      case "jul": {
        return "Jul";
      }
      case "aug":
        return "Aug";
      case "sep": {
        return "Sep";
      }
      case "oct":
        return "Oct";

      case "nov":
        return "Nov";
      case "dec":
        return "Dec";
      default: {
        return "Jan";
      }
    }
  };

  // vertsion 3.0 ---------------------------------
  async getCalenderDates() {
    // try {
    //   console.log("try start:)")
    //   let date = new Date();
    //   let m = date.getMonth() + 1;
    //   let month = m.toString().length >= 2 ? m : '0' + m
    //   let e = 32 - new Date(month, date.getFullYear(), 32).getDate();
    //   let startDate = `${date.getFullYear()}-${month}-01T00:00:00.000Z`
    //   let endDate = `${date.getFullYear()}-${month}-${e}T00:00:00.000Z`
    //   let reqObj = { startDate, endDate, iUserId: this.state.iUserId };
    //   let response = await WebService.sessionData(reqObj);
    //   this.setState({ isLoading: false });
    //   console.log("response:-----------------)", response)
    //   if (response.status) {
    //     console.log("try if response:)")
    //   }
    //   else {
    //     console.log("try else data not found:)")
    //   }
    // }
    // catch (err) {
    //   console.log("Error:::catch:)", err)
    // }
  }
  // end version 3.0 ----------------------------------

  /**
   * set timezone which is  getting from the DB if the timezone is not got then set the default time zone which is saved with the user profile
   */
  convertedDateWithTimezone(tz, date) {
    const convertedTime = moment_timezone.utc(date).tz(tz);
    // console.log("Session Timezone::--", tz, ",Session Date :- ", date, ",Converted Date", convertedTime.format());
    return convertedTime.format();
  }
  render() {
    const {
      modalShow,
      setModalShow,
      isWeeksTxt,
      machineTxt,
      isMachineTxt,
      weeks,
      isViewFrom,
      isQuestionnaireDue,
      isTouchTestDue,
      selMonth,
      defaultIndexMonth,
      selMultiMonth,
    } = this.state;
    let isWeeksTitle =
      this.state.isWeeksTxt === "" || undefined ? "Weeks" : isWeeksTxt;
    let machineTitle =
      this.state.isMachineTxt === "" || undefined ? "3 mm" : isMachineTxt;
    return (
      <div className="main-HomeProgress">
        {this.props.match.params?.token ? (
          console.log("yess")
        ) : (
          <>
            {isViewFrom === "adminDashboard" && (
              <AdminHeader {...this.props}></AdminHeader>
            )}
            {this.state.isViewFrom === "home" ? (
              <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu>
            ) : this.state.isViewFrom === "adminProgress" ||
              this.state.isViewFrom === "adminDashboard" ? (
              <AdminSideMenu {...this.props}></AdminSideMenu>
            ) : (
              <HomeSideMenu {...this.props}></HomeSideMenu>
            )}
            {isViewFrom !== "adminDashboard" && (
              <HomeHeader {...this.props}></HomeHeader>
            )}
          </>
        )}
        <div className="inner-HomeProgress">
          <Scrollbars className="home-Scroll">
            <div className="main-Container">
              {/* start tabs  */}

              <Tabs
                defaultActiveKey="graph"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                {/* garph view ------------------------------- */}
                <Tab eventKey="graph" title="Graph">
                  <div className="dropdown-Section">
                    <div
                      className={
                        this.state.infoView ? "divclass show " : "divclass"
                      }
                      onClick={() =>
                        this.setState({ infoView: !this.state.infoView })
                      }
                    ></div>
                    <div
                      className={
                        this.state.infoView ? "tool-Tips show" : "tool-Tips"
                      }
                    >
                      <a
                        href="void:0()"
                        class="tip icon-info cursor"
                        onClick={() =>
                          this.setState({ infoView: !this.state.infoView })
                        }
                      >
                        <span className="info-detail">
                          Device Wear and Touchtest graphs can be use to keep
                          track of your progress. Device wear tells you exactly
                          how much you have worn the appliance. The touch graph
                          is simply to show you your muscles are relaxing and
                          that you are getting less touches over time. The main
                          purpose of the touch test is to set up a trigger in
                          the brain.
                          <br />
                          <br />
                          Data on both graphs can be viewed on weekly, monthly
                          and 2 monthly timeline basis.
                          <br />
                          <br />
                          Photographs uploaded on a particular day will be
                          represented by a "Camera" icon, similarly overuse
                          reported will be represented by an "Exclamation" icon.{" "}
                        </span>
                      </a>
                    </div>

                    {this.state.isDropdownDisabled ? (
                      <DropdownMenu
                        title={isWeeksTitle}
                        onSelect={this.handleSelect.bind(this)}
                        onItemSelect={this.onItemHandleEvent.bind(this)}
                        options={optionsProgress}
                      ></DropdownMenu>
                    ) : (
                      <DropdownMenu
                        title={isWeeksTitle}
                        onSelect={this.handleSelect.bind(this)}
                        onItemSelect={this.onItemHandleEvent.bind(this)}
                        options={optionsProgress}
                      ></DropdownMenu>
                    )}

                    {(this.state.isWeeks === "1" ||
                      this.state.isWeeks === "2") && (
                      // <div className="dropdown-Section">
                      <DropdownMenu
                        title={
                          this.state.isyearslected === "" || undefined
                            ? "Yearly"
                            : this.state.isyearslected
                        }
                        // onSelect={this.handleSelectyear.bind(this)}
                        onItemSelect={this.handleSelectyear.bind(this)}
                        options={this.state.yearlyoption}
                      ></DropdownMenu>
                      // </div>
                    )}
                  </div>
                  <div className="inline">
                    <div className="weekly-Data home-Scroll">
                      <ProgressWeekly
                        selMonth={selMonth}
                        selMultiMonth={selMultiMonth}
                        defaultIndexMonth={defaultIndexMonth}
                        onClickTwoMonthData={this.onClickTwoMonthData.bind(
                          this
                        )}
                        clickIndex={this.state.clickIndex}
                        onCickMonthData={this.onCickMonthData.bind(this)}
                        onWeeksData={this.onWeeksClick.bind(this)}
                        title={this.state.isWeeks == 0 ? "Weeks" : "Months"}
                        selectedTime={this.state.isWeeks}
                        weeeks={this.state.weeks}
                        data={
                          this.state.isWeeks == 0
                            ? this.state.weeks
                            : this.state.isWeeks === "1" ||
                              this.state.isWeeks === "3"
                            ? this.state.months
                            : this.state.monthly
                        }
                      ></ProgressWeekly>
                    </div>
                    {/* {(this.state.isWeeks === "1" ||
                      this.state.isWeeks === "2") && (
                      <div className="dropdown-Section">
                        <DropdownMenu
                          title={
                            this.state.isyearslected === "" || undefined
                              ? "Yearly"
                              : this.state.isyearslected
                          }
                          // onSelect={this.handleSelectyear.bind(this)}
                          onItemSelect={this.handleSelectyear.bind(this)}
                          options={this.state.yearlyoption}
                        ></DropdownMenu>
                      </div>
                    )} */}
                  </div>
                  <div className="main-HighChart-Section">
                    <div className="width50">
                      <h5 className="title">Device Wear Graph</h5>
                      <div className="dropdown-Section">
                        <div className="ampmSection">
                          <a
                            href="void:0()"
                            className="choices active"
                            onClick={() => this.onReadMore3mmClick()}
                          >
                            <span className="list-icon-square-purple"></span> PM
                          </a>
                          <a
                            href="void:0()"
                            className="choices mLeft10"
                            onClick={() => this.onReadMore3mmClick()}
                          >
                            <span className="list-icon-square-lp"></span> AM
                          </a>
                        </div>
                        <div className="iconupsec">
                          <label className="label">
                            <span className="icon-camera icon-css"></span>
                            Photos Uploaded
                          </label>

                          <label className="label mLeft20">
                            <span className="icon-warning icon-css"></span>
                            Overuse Reported
                          </label>
                        </div>
                        <DropdownMenu
                          title={machineTitle}
                          onSelect={this.handleSelectMachine.bind(this)}
                          onItemSelect={this.onHandlEvent.bind(this)}
                          options={machine}
                        ></DropdownMenu>
                      </div>
                      <div className="highChart-Section">
                        {this.state.touchTestData === undefined && (
                          <p className="noData">No Data</p>
                        )}
                        {/* {typeof this.state.pmGraphData} */}
                        <Highchart
                          graphDate={this.state.graphDate}
                          amData={this.state.amGraphData}
                          pmData={this.state.pmGraphData}
                        ></Highchart>
                      </div>
                      <div className="iconsec">
                        <label className="label">
                          <span className="icon-camera icon-css"></span>
                          Photos Uploaded
                        </label>

                        <label className="label mLeft20">
                          <span className="icon-warning icon-css"></span>
                          Overuse Reported
                        </label>
                      </div>
                      {this.state.isQuestionnaireDue === "true" && (
                        <label className="biweeklyAlert label">
                          <span className="icon-warning icon-css"></span>
                          You havn't answered your biweekly questionnaire since
                          last 1 month.
                        </label>
                      )}
                    </div>
                    <div className="width50">
                      <h5 className="title">Touch Test Graph</h5>
                      <div className="dropdown-Section right">
                        <DropdownMenu
                          title={machineTitle}
                          onSelect={this.handleSelectMachine.bind(this)}
                          onItemSelect={this.onHandlEvent.bind(this)}
                          options={machine}
                        ></DropdownMenu>
                      </div>
                      <div className="highChart-Section">
                        {this.state.touchTestData === undefined && (
                          <p className="noData">No Data</p>
                        )}

                        <Highchart
                          graphTouchTestDate={this.state.graphTouchTestDate}
                          graphTouchTestData={this.state.graphTouchTestData}
                        ></Highchart>
                      </div>
                      {this.state.isTouchTestDue === "true" && (
                        <label className="biweeklyAlert label">
                          <span className="icon-warning icon-css"></span>
                          No touch test has been performed since last 2 weeks.
                        </label>
                      )}
                    </div>
                  </div>
                </Tab>
                {/* calenmder view ********************************************** */}
                <Tab eventKey="calender" title="Calendar">
                  <Calender
                    token={this.props.match?.params?.token}
                    iUserId={this.state.iUserId}
                  />
                </Tab>
              </Tabs>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default Homeprogress;
