import React, { useState } from 'react';
import Button from '../../../components/Button/button';
import { setLocalActiveClass, getStoredData, setStoredData } from "../../../utils/helper";
import './5mmhome.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../../components/homesidebar/homesidebar'
import HomeHeader from '../../../components/homeheader/homeheader';
import TimerBar from '../../../components/timer/timer';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import DropdownMenu from '../../../components/dropdown/dropdown';
import { Row, Col, OverlayTrigger, Tooltip, ButtonToolbar } from 'react-bootstrap';
import PopupModal from '../../../components/modal/modal';
import TextField from '../../../components/textfield/textfield';
import WebService from "../../../utils/Webservice"
import InputMask from 'react-input-mask';
import moment from 'moment';
import Overlay from "../../../components/overlay/overlay";
const optionsDevice = ['3 mm ', '5 mm']
const optionsTimer = ['15 min', '20 min']

class FiveMMHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showToast: true,
            setModalShow: false,
            modalShow: getStoredData('isShowPopup') === '' || getStoredData('isShowPopup') === null || getStoredData('isShowPopup') === 'undefined' ? false : true,
            nDevice: getStoredData('nDeviceType') === '' || getStoredData('nDeviceType') === 'undefined' || getStoredData('nDeviceType') == null ? 'Device' : getStoredData('nDeviceType'),
            nDeviceType: (getStoredData('nDeviceType') !== '' || getStoredData('nDeviceType') !== null) ? (getStoredData('nDeviceType') === '5 mm' ? 1 : 0) : 0,
            nMinutes: getStoredData("touchtest3mm") === 'undefined' || getStoredData("touchtest3mm") === null ? "15" : getStoredData("touchtest3mm"),
            _id: getStoredData("_id"),
            isViewFrom: this.props.history.location?.state?.isViewFrom,
            nPrefered: moment(new Date(), 'ddd DD-MMM-YYYY,A').format('A') === 'AM' ? 0 : 1,
            linkDisable: true,
            minDisable: false,
            deviceDisableEnable: false,
            nDeviceShow: false,
            isOnBoardingComplete: getStoredData("isOnBoardingComplete") === 'undefined' || getStoredData("isOnBoardingComplete") === 'false' ? false : getStoredData("isOnBoardingComplete"),
            errorText: false,
            isOveruse: getStoredData("isOveruse") === undefined || getStoredData("isOveruse") === 'undefined' ? false : JSON.parse(getStoredData("isOveruse")),
            isPhotographsDue: getStoredData("isPhotographsDue") === undefined || getStoredData("isPhotographsDue") === 'undefined' ? false : JSON.parse(getStoredData("isPhotographsDue")),
            isQuestionnaireDue: getStoredData("isQuestionnaireDue") === undefined || getStoredData("isQuestionnaireDue") === 'undefined' ? false : JSON.parse(getStoredData("isQuestionnaireDue")),
            isTwoMonthsCompleted: getStoredData("isTwoMonthsCompleted") === undefined || getStoredData("isTwoMonthsCompleted") === 'undefined' ? false : JSON.parse(getStoredData("isTwoMonthsCompleted")),
            btnDisable: true,
            touchtest3mm: getStoredData("touchtest3mm") === undefined || getStoredData("touchtest3mm") === 'undefined' ? "15" : getStoredData("touchtest3mm"),
            title: '',
            timer: '',
            touchCount: 0,
            touchtest5mm: getStoredData("touchtest5mm") === undefined || getStoredData("touchtest5mm") === 'undefined' ? "15" : localStorage.getItem("touchtest5mm")
        }
        setLocalActiveClass("Home")
    }
    onGoClick() {
        this.props.history.push('/setreminder')
    }
    handleSelect(eventKey) {
        this.setState({ nDevice: eventKey })
        { eventKey == 0 ? this.setState({ nMinutes: this.state.touchtest3mm }) : this.setState({ nMinutes: this.state.touchtest5mm }) }
        { eventKey == 0 ? setStoredData('nDeviceType', "3 mm") : setStoredData('nDeviceType', "5 mm") }
    }
    onItemSelect(e, option, eventKey) {
        this.setState({
            nDevice: e.target.text,
            nDeviceType: eventKey,
        })
        { eventKey != 1 ? this.setState({ btnDisable: false, errorText: false }) : this.setState({ btnDisable: true, errorText: true }) }
        { (this.state.nMinutes.length === 5) ? this.setState({ btnDisable: false, errorText: false }) : this.setState({ btnDisable: true, errorText: true }) }
    }
    handleChange(event) {
        let sTime = event.target.value.split(':')
        let min = Number(sTime[0])
        let sec = sTime[1]
        { min > 60 ? this.setState({ errorTextMin: true }) : this.setState({ errorTextMin: false }) }
        { sec > 59 ? this.setState({ errorTextSec: true }) : this.setState({ errorTextSec: false }) }
        { min === 60 && sec > 0 ? this.setState({ errorTextMinSec: true }) : this.setState({ errorTextMinSec: false }) }
        { min === 0 && (sec <= 0 || sec === undefined || sec === '') ? this.setState({ errorText: true }) : this.setState({ errorText: false }) }

        this.setState({ nMinutes: event.target.value }, () => {
            { (this.state.nMinutes.length === 5 && !this.state.errorTextMin && !this.state.errorTextSec && !this.state.errorText && !this.state.errorTextMinSec) ? this.setState({ btnDisable: false, errorText: false }) : this.setState({ btnDisable: true, errorText: true }) }
        })
    }
    onBackButtonEvent(event) {
        event.preventDefault();
        this.setState({ modalShow: false })
    }
    componentDidMount() {
        let { isTwoMonthsCompleted, isViewFrom, nMinutes, touchtest3mm, touchtest5mm } = this.state
        if (isTwoMonthsCompleted) {
            this.setState({ nDeviceShow: true })
        } else {
            this.setState({ nDeviceShow: false })
        }
        let minute = this.convertTime(nMinutes)
        let minute3mm = this.convertTime(touchtest3mm)
        let minute5mm = this.convertTime(touchtest5mm)
        this.setState({ nMinutes: minute, touchtest3mm: minute3mm, touchtest5mm: minute5mm }, () => {
            { (this.state.nMinutes.length === 5 || this.state.touchtest3mm.length === 5 || this.state.touchtest5mm.length === 5) ? this.setState({ errorText: false, btnDisable: false }) : this.setState({ errorText: true, btnDisable: true }) }
        })

    }

    convertTime(nMinutes) {
        if (nMinutes.includes('.')) {
            let sec
            let sTime = nMinutes.split('.')
            let min = ('0' + Number(sTime[0])).slice(-2)
            if (sTime[1].length === 2) {
                sec = sTime[1]
            } else {
                sec = sTime[1] + "0"
            }
            return min + ':' + sec
        } else {
            let minData = nMinutes + ".0"
            let sTime = minData.split('.')
            let min = ('0' + Number(sTime[0])).slice(-2)
            let sec = ('00')
            return min + ':' + sec
        }
    }


    clickLink(linkDisable, title) {
        {
            linkDisable === true ? this.setState({ linkDisable: false, minDisable: true, deviceDisableEnable: true }) :
                this.setState({ linkDisable: true, minDisable: false, deviceDisableEnable: false })
        }
        this.setState({ title })
    }

    async  onSubmit(timer, touchCount, title, displayTime) {
        let { nDeviceType, nPrefered } = this.state
        this.setState({ isLoading: true, linkDisable: true });
        try {
            let obj = {
                iUserId: this.state._id,
                nTouchCount: touchCount,
                // sTotalTime: displayTime === '' ? timer : displayTime,
                // sTotalTime: "00.00",
                sTotalTime: timer,
                nDeviceType: nDeviceType,
                nPrefered: nPrefered,
                dCreatedAt: moment().format()
            };
            let response = await WebService.UserTouchTest(obj);
            this.setState({ isLoading: false });

            if (response.status) {
                if (displayTime === '') {
                    this.handleSuccessResponse(response, timer, title)
                } else {
                    setStoredData("touchTestId", response.data.touchTestId)
                    this.props.history.push(('/5mmtestreport'), { isViewFrom: this.state.isViewFrom, isDevicesType: this.state.nDeviceType })
                }
                if (nDeviceType === 0) {
                    setStoredData("touchtest3mm", timer)
                } else {
                    setStoredData("touchtest5mm", timer)
                }

            } else {
                this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
                // console.log("response" + response.status);
            }
        } catch (error) {
            // console.log(error);
        }
    }
    handleSuccessResponse(response, timer, title) {
        this.setState({ toaster: true, message: response.MESSAGES, isShow: true })
        setStoredData("touchTestId", response.data.touchTestId)

        let displayTime = this.state.nMinutes.replace(":", ".")
        if (timer < displayTime && title === 'start session') {
            this.setState({ nMinutes: "0000", btnDisable: true })
        }
        else if (timer == displayTime) {
            this.setState({ modalShow: true })
        }
    }
    onManualClick() {
        this.props.history.push('/manualentry', { nDeviceType: this.state.nDeviceType })
    }
    showToast() {
        this.setState({
            show: true
        })
        setTimeout(() => {
            this.setState({
                show: false
            })
        }, 3000)
    }
    setModalShow = (show, e) => {
        this.props.history.push('/setreminder')
    }
    onModalClose = (show) => {
        setStoredData("isShowPopup", '')
        this.setState({ modalShow: !this.state.modalShow })
    }
    onCompleteLaterClick() {
        this.props.history.push('/setreminder')
    }
    onClickPopup(page) {
        switch (page) {
            case 'overuse':
                this.props.history.push("/signofoveruse");
                break;
            case 'simpleStepsAdd':
                this.props.history.push("/simpleStepsAdd");
                break;
            case 'criteriachange':
                this.props.history.push("/criteriachange");
                break;
            default:
                break;
        }
    }
    onSideItemClick(page) {
        switch (page) {
            case "Photos":
                this.props.history.push("/photos", { isViewFrom: "homeSidemenu" });
                break;
            case "Questionnaire":
                this.props.history.push('/QuestionBiweekly', { isViewFrom: 'home' })
                break;
            default:
                break;
        }
    }
    onClickReportSignOfOverUse() {
        //console.log(getStoredData('touchTestId'), "getStoredData('touchTestId') ");
        if (getStoredData('touchTestId') !== null && getStoredData('touchTestId') !== '') {
            this.props.history.push(('/5mmtestreport'), { isViewFrom: this.state.isViewFrom, isDevicesType: this.state.nDeviceType })
        }
        else if (getStoredData('touchTestId') === '' && this.state.title === 'stop session') {
            this.props.history.push(('/5mmtestreport'), { isViewFrom: this.state.isViewFrom, isDevicesType: this.state.nDeviceType, timer: this.state.timer, totalNoOfTouch: this.state.touchCount })
        }
        else {
            this.onSubmit('00.00', 0, "start sessions", "00.00")
        }
    }

    redireactToReport(timer, touchCount) {
        this.setState({ timer, touchCount })
    }
    onItemClick() {
        localStorage.clear();
    }
    render() {
        const { modalShow, setModalShow, nDeviceType, nDeviceShow, isPhotographsDue, deviceDisableEnable, btnReportSignOveruse, isQuestionnaireDue, minDisable, isOnBoardingComplete, nMinutes, isOveruse, errorText, linkDisable } = this.state
        return (
            <div className='main-Home5TouchTest'>
                <HomeSideMenu {...this.props}></HomeSideMenu>
                <HomeHeader {...this.props}></HomeHeader>
                {isOnBoardingComplete === false ? <Overlay onItemClick={this.onItemClick.bind(this)}></Overlay> :
                    <div className='inner-5TouchTest'>
                        <Scrollbars className='home-Scroll homePage'>
                            <div className='main-Container cls hide-dashboard-wear-clock'>
                                <div className="dueOptions">
                                    <ul>
                                        <li><a className={isPhotographsDue === true ? "list-iconlink" : ""} onClick={() => isPhotographsDue === true && this.onSideItemClick("Photos")}><span className={isPhotographsDue === true ? "list-icon active" : "list-icon"}></span>Photographs due</a></li>
                                        <li><a className={isQuestionnaireDue === true ? "list-iconlink" : ""} onClick={() => isQuestionnaireDue === true && this.onSideItemClick("Questionnaire")}><span className={isQuestionnaireDue === true ? "list-icon active" : "list-icon"}></span>Questionnaire due</a></li>
                                    </ul>
                                </div>

                                <div className='dropdown-Section toolhome'>
                                    <div className='inner-Dropdown homeFivemm'>
                                        {/* {nDeviceShow && <DropdownMenu disabled={deviceDisableEnable} onSelect={this.handleSelect.bind(this)} onItemSelect={this.onItemSelect.bind(this)} title={this.state.nDevice} options={optionsDevice}></DropdownMenu>} */}
                                        <InputMask {...this.props} disabled={minDisable} value={nMinutes === '00:00' ? '' : nMinutes} placeholder={'mm:ss'} onChange={this.handleChange.bind(this)} mask="99:99" type="tel" className="customMask customMinute" maskChar={null} />
                                        {(errorText === true) ? <span className='error-msg'>Touch test should not be more than 60 minutes</span> : ''}
                                        <div className={this.state.infoView ? 'divclass show ' : 'divclass'} onClick={() => this.setState({ infoView: !this.state.infoView })}></div>
                                        <div className={this.state.infoView ? 'tool-Tips show' : 'tool-Tips'} >
                                            <a className="tip icon-info cursor" onClick={() => this.setState({ infoView: !this.state.infoView })}>
                                                <span>For every wear session first 5 minutes can be used as a touch test, it will continue as a wear session but feature to record touches will be disabled after 5 minutes.
                                                <br />
                                                    If you are changing to a higher device, the touch test should be done with the higher device.
                                                <p className="alignC margin0 paddingT24"><a onClick={() => this.onClickPopup("overuse")} className="aLink padding0">What are signs of overuse?</a></p>
                                                    <p className="alignC margin0"><a onClick={() => this.onClickPopup("simpleStepsAdd")} className="aLink padding0 marginT10">Your Programme in 5 simple steps.</a></p>
                                                    <p className="alignC"><a onClick={() => this.onClickPopup("criteriachange")} className="aLink padding0 marginT10">Criteria for changing to 5mm device</a></p>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className='timer-Progessbar'>
                                    <TimerBar color={this.state.nDeviceType}
                                        onTimerStart={this.onCompleteLaterClick.bind(this)}
                                        nMinutes={nMinutes} disabled={this.state.btnDisable}
                                        redireactToReport={this.redireactToReport.bind(this)}
                                        isViewFrom={this.state.isViewFrom}
                                        clickLink={this.clickLink.bind(this)}
                                        onSubmit={this.onSubmit.bind(this)}></TimerBar>
                                    <PopupModal show={modalShow} onClose={() => { this.onModalClose(modalShow) }} onHide={() => this.setModalShow(modalShow)}></PopupModal>
                                </div>
                                <div className='bottom-Section alignC'>
                                    <h5 >All wear sessions have to be entered manually.</h5>
                                    <Button className={this.state.linkDisable ? "aLink" : 'disableLink'} disabled={!this.state.linkDisable} onClick={() => { this.state.linkDisable && this.onManualClick() }}>Record wear time</Button>
                                    {/* <Button title="Record wear time" className={this.state.linkDisable ? "aLink" : 'disableLink'} onClick={() => this.state.linkDisable && this.onManualClick()} ></Button> */}

                                    <Button onClick={this.onClickReportSignOfOverUse.bind(this)} title="Report signs of overuse"></Button>

                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                }
            </div>
        )
    }
}




export default FiveMMHome;

