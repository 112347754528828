const CONSTANTS = {
  // API_BASE_URL: "https://oralift-web.herokuapp.com/api/v1/",

  //------ new project baseurl
  API_BASE_URL: "https://oraliftbackend.24livehost.com:4000/api/v1/", //----- Development
  // API_BASE_URL: "http://localhost:4000/api/v1/", //----- Development
  // API_BASE_URL: "https://oraliftbackendlive.24livehost.com:4001/api/v2/",  //----- Live
  // API_BASE_URL: "https://oraliftbackendlive.24livehost.com:4002/api/v2/",  //---- Live/V2 (for testing)
  // API_BASE_URL: "http://localhost:4001/api/v1/",  //----- localhost
  // ORALIFT_DB_API_URL: "http://dev.zudu.co.uk/oralift/wp-json/api/v1/check_code/?ac=",
  ORALIFT_DB_API_URL: "https://oralift.com/gb/wp-json/api/v1/check_code/?ac=",

  API: {
    USER_VERIFICATION: "user/userVerificationCode",
    USER_AGREEMENT: "user/addUserAgreement",
    GET_USER_AGREEMENT: "user/getUserAgreements",
    ABOUT_YOU: "user/aboutYou",
    WHAT_YOU_WANT: "user/whatYouWant",
    ONBOARDING_COMPLETED: "user/onBoardingCompleted",
    SMILE_PHOTO: "userPhoto/addUserSmilePhoto",
    FRONT_PHOTO: "userPhoto/addUserNonSmilePhoto",
    LEFT_PHOTO: "userPhoto/addUserLeftPhoto",
    RIGHT_PHOTO: "userPhoto/addUserRightPhoto",
    BODY_LEFT_PHOTO: "userPhoto/addUserFullLeftPhoto",
    BODY_RIGHT_PHOTO: "userPhoto/addUserFullRightPhoto",
    GET_PHOTOS: "userPhoto/getUserPhotos",
    GET_USER_DETAIL: "user/getUser",
    POST_TOUCH_TEST: "touchTest/userTouchTest",
    POST_SAVE_USER_PREFERENCE: "user/saveUser",
    POST_TOUCH_REPORT: "touchTest/addTouchTestReport",
    POST_TOUCH_COUNT: "touchTest/getTouchTestCount",
    POST_SAVE_USER: "user/saveUserData",
    USER_PROGRESS: "touchTest/userProgressData",
    USER_PROGRESS_CALENDER: "touchTest/userProgressDataCalender",
    TOUCHTEST_PROGRESS_DATA: "touchTest/touchTestProgress",
    TOUCH_TEST_PROGRESS: "touchTest/getTouchTestRecord",
    SET_REMINDER: "UserReminder/setReminder",
    ADMIN_LOGIN: "user/adminLogin",
    ADMIN_LOGOUT: "user/userLogout",
    ADMIN_PROFILE_UPDATE: "user/userProfileUpdate",
    USER_PROFILE_UPDATE: "user/userProfileUpdate",
    ADMIN_DASHBOARD: "user/dashboardData",
    ADMIN_CHANGE_PASSWORD: "user/changePassword",
    ADMIN_FORGOT_PASSWORD: "user/forgotPassword",
    USER_SET_FORGOT_PASSWORD: "user/saveNewPassword",
    SET_QUESTIONNAIRE: "Questionnaire/setQuestionnaireByweekly",
    USER_FEEDBACK: "user/sendFeedback",
    RESET_PASSWORD_LINK: "user/checkResetPasswordLink",
    ADMIN_RESET_PASSWORD: "user/resetPassword",
    NOTIFICATION_LIST: "notification/getNotificationData",
    GET_QUESTIONNAIRE: "Questionnaire/getQuestionnaireByweekly",
    GET_REPORT_OVERUSE: "touchTest/getOverUseReport",
    GENERATE_CODE: "user/generateVerificationCode",
    USER_BY_ID: "user/getUser",
    STORED_V_CODE: "user/storedVerificationCode",

    //----------------- version 3.0 -------------------------
    SESSION_DATA: "touchTest/getSessionData",
  },
  PHOTOS: {
    JPGALLOW: "only JPG, JPEG, PNG  files are allowed.",
    FILE_SIZE: "File too small, please select a file greater than 1mb.",
    IMG_RESOLUTION: "Image dimension should be within 376*56",
  },

  MESSAGES: {
    MULTIPLE_SELECTION:
      "Click on a row to avail multiple Deletion, Enable and Disable option.",
    VALIDATION: {
      FULNAME: "Name can’t be empty",
      PASSWORD: "Password can’t be empty",
      INVALID_PASSWORD: "Password is Invalid",
      CONFIRM_PASSWORD: "Confirm password not match",
      EMAIL: "Email is Invalid",
      EMAIL_LENGTH: "Email can’t be empty",
      OTP: "Please enter valid otp",
      FIRSTNAME_LENGTH: "Please enter First Name",
      LASTNAME_LENGTH: "Please enter Last Name",
      FIRSTNAME: "Invalid First Name",
      LASTNAME: "Invalid Last Name",
      PHONE: "Invalid Phone Number",
      PHONE_REQ: "Please enter Phone Number",
      PHONE_LENGTH: "Phone Number should have min. 10 and max. 13 digits",
      PHONE_VALID: "Invalid Phone Number",
      REGION: "Please enter valid Region",
      REGION_LENGTH: "Please enter Region",

      TOPIC_LENGTH: "Please enter Topic Title",
      TOPIC_VALID: "Invalid training Topic Title",
      TOPIC_DESC_LENGTH: "Please enter Topic Description",
      TOPIC_DESC: "Please enter valid Topic Description",

      TRAINING_TITLE_LENGTH: "Please enter Training Title",
      TRAINING_TITLE_VALID: "Invalid Training Title",
      TRAINING_DESC_LENGTH: "Please enter Training Description",
      TRAINING_USER_LENGTH: "Please enter Users Allowed Count",
      TRAINING_USER_VALID: "Invalid Users Allowed Count",
      TRAINING_DURATION_LENGTH: "Please enter Training Duration",
      TRAINING_DURATION_VALID: "Invalid Training Duration",
      TRAINING_VENUE_LENGTH: "Please enter Training Venue",
      TRAINING_TIME_LENGTH: "Please enter Training Time",
      TRAINING_DATE_LENGTH: "Please enter training date.",
      TRAINING_DATE_VALID: "Invalid training date.",
      TRAINING_COST_LENGTH: "Please enter training cost",
      TRAINING_COST_VALID: "Invalid training cost",

      QUESTION_ADD_WARNING:
        "Please disable one of the following question to add new question.",

      RESOURCE_TITLE_LENGTH: "Please enter Document title",
      RESOURCE_TITLE_VALID: "Please enter valid Document title",
      RESOURCE_LINK_LENGTH: "Please enter Document weblink",
      RESOURCE_LINK_VALID: "Please enter valid Document weblink",
      RESOURCE_DESC_LENGTH: "Please enter resource description",

      VALID_IMAGE_FILE:
        "Invalid file format. Images and video file formats are only allowed",
      VALID_PDF_FILE: "Invalid file format. PDF file formats are only allowed",
      FILE_SIZE_5_MB: "Max 5 MB files are allowed.",
      FILE_SIZE_2_MB: "Max 2 MB files are allowed.",
      FILE_SIZE_4_MB: "Max 4 MB files are allowed.",

      OFFICE_TITLE_LENGTH: "Please enter office name",
      OFFICE_TITLE_VALID: "Invalid office name.",
      OFFICE_ADDRESS_LENGTH: "Please enter office address.",
      OFFICE_AREA_LENGTH: "Please enter office area.",
      OFFICE_AREA_VALID: "Invalid office area.",
      OFFICE_CITY_LENGTH: "Please enter office city.",
      OFFICE_CITY_VALID: "Invalid office city.",
      OFFICE_NAME_LENGTH: "Please enter person name.",
      OFFICE_NAME_VALID: "Invalid contact person name.",
      OFFICE_PHONE_LENGTH:
        "Phone number should have min. 10 and max. 13 digits",
      OFFICE_PHONE_VALID: "Invalid Phone number.",
      OFFICE_LINK_LENGTH: "Please enter Website link.",
      OFFICE_LINK_VALID:
        "Invalid website link.Preffered format https://example.com.",
      OFFICE_EMAIL_VALID: "Email is Invalid!",
      OFFICE_LINK_LENGTH: "Please enter Email",

      RESOURCE_CATEGORY_LENGTH: "Please select resource category.",
      NUMBER_ATTENDING: "Number attending is invalid.",

      EVENT_TITLE_LENGTH: "Please enter event title.",
      EVENT_TITLE_VALID: "Please enter valid event title.",
      EVENT_DESCRIPTION_LENGTH: "Please enter event description.",
      EVENT_DATE_LENGTH: "Please enter event date.",
      EVENT_TIME_LENGTH: "Please enter event time.",
      EVENT_LOCATION_LENGTH: "Please enter event location.",
    },
    SUCCESS: {
      AGREEMENT:
        "Congratulations !! You have successfully accepted the agreement.",
    },
  },
  REGEX: {
    // EMAIL: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
    EMAIL: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    //  EMAIL: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
    SPECIAL_CHARECTERS: /[!@#\$%\^\&*\)\(+=._-]/g,
    ONLY_CHARECTERS: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
    PASSWORD: /^(?=.*?[A-Z])(?=.*[!@#$%^&*])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
    TITLE: /^[ A-Za-z0-9\,'-]*$/,
    DATE: /^\d{2}\/\d{2}\/\d{4}$/,
    CAPITAL: /[A-Z]/g,
    LOWERCASE: /[a-z]/g,
    NUMBER: /[0-9]/g,
    PHONE_NUMBER:
      /^\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?$/,
    //^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$
    WEBSITE:
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm,
    OFFICE_TITLE: /^[ A-Za-z0-9\.-]*$/,
    CONTACT_NUMBER: /^[0-9]{10,13}$/,
    EVENT_TITLE: /^[ A-Za-z0-9\,&/'-]*$/,
    REGION: /^[ A-Za-z\,&/-]*$/,
  },
  KEYS: {
    keyToken: "token",
    keyUserId: "userId",
    keyUserName: "userName",
    keyUserType: "userType",
    keyCheckedToast: "keyCheckedToast",
    KeyAgreementFlag: "isAgreementFilled",
    KeyQuestionaryFlag: "isQuestionnairyFilled",
    KeyPhotoAdded: "isPhotoAdded",
    Key5mmTest: "5mmTest",
    Key3mmTest: "3mmTest",
    KeyPreferencesFilled: "preferecesFilled",
  },
  COLOR: {
    SELECTED_GRAY: "rgba(213, 217, 231, .20)",
  },

  TIMEPICKERTOGGLE: true,
};

export default CONSTANTS;
