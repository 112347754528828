import React, { useState } from 'react';
import { setLocalActiveClass, setStoredData } from "../../../utils/helper";
import './adminlogin.scss';
import { Scrollbars } from "react-custom-scrollbars";
import Button from "../../../components/Button/button";
import TextField from '../../../components/textfield/textfield';
import CONSTANTS from '../../../utils/constants';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import WebService from "../../../utils/Webservice"
import { messaging } from "../../../init-fcm";
import * as firebase from "firebase/app";

class AdminLogin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginForm: true,
            forgotForm: false,
            email: {
                value: "",
                isValid: false,
                message: ""
            },
            password: {
                value: "",
                isValid: false,
                message: ""
            },
            forgetEmail: {
                value: "",
                isValid: false,
                message: ""
            },
            errorMessage: '',
            isToastShow: false,
            showPass: false,
            server_key: '',
            isLoading: false
        }


    }
    componentDidMount() {
        let me = this;
        if (firebase.messaging.isSupported()) {
            messaging.requestPermission()
                .then(async function () {
                    const token = await messaging.getToken();
                    me.setState({ server_key: token })
                    // console.log(token)
                })
                .catch(function (err) {
                    // console.log("Unable to get permission to notify.", err);
                });
        }
    }
    onSubmit() {
        this.validateFields('email', this.state.email.value)
        this.validateFields('password', this.state.password.value)
        this.adminLoginWebServices()
    }
    onKeyDown(e) {
        e.keyCode == 13 &&
            this.adminLoginWebServices()
    }

    handleUserInput = e => {
        let name = e.target.name;
        let value = e.target.value;
        var obj = {};
        obj[name] = {
            value: value
        };
        this.setState({
            showEye: name == "password" && value != "" ? true : false
        });
        this.setState(obj, () => {
            this.validateField(name, value);
        });
    };
    // text on change //
    validateField(fieldName, value) {
        let valid = false;
        let message = "";
        switch (fieldName) {
            case "email":
                valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
                !valid &&
                    (message =
                        value.length <= 0
                            ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH
                            : CONSTANTS.MESSAGES.VALIDATION.EMAIL);
                break;
            case "password":
                valid = value.length > 0;

                !valid && (message = CONSTANTS.MESSAGES.VALIDATION.PASSWORD);
                break;
            case "forgetEmail":
                valid = value.match(CONSTANTS.REGEX.EMAIL);
                !valid && (message = CONSTANTS.MESSAGES.VALIDATION.EMAIL);
                break;
            default:
                break;
        }
        var obj = {};
        obj[fieldName] = {
            message: message,
            isValid: valid,
            value: value
        };
        this.setState(obj, () => {
            fieldName == "forgetEmail"
                ? this.validateForgotForm()
                : this.validateForm();
        });
    }
    // validation on Button//
    validateFields(fieldName, value) {
        let valid = false;
        let message = "";

        switch (fieldName) {
            case "email":
                valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
                !valid &&
                    (message =
                        value.length <= 0
                            ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH
                            : CONSTANTS.MESSAGES.VALIDATION.EMAIL);
                break;
            case "password":
                valid = value.length > 7 && value.match(CONSTANTS.REGEX.SPECIAL_CHARECTERS) && value.match(CONSTANTS.REGEX.NUMBER)
                    && value.match(CONSTANTS.REGEX.CAPITAL) && value.match(CONSTANTS.REGEX.LOWERCASE);

                !valid &&
                    (message =
                        value.length <= 0
                            ? CONSTANTS.MESSAGES.VALIDATION.PASSWORD
                            : CONSTANTS.MESSAGES.VALIDATION.INVALID_PASSWORD);
                break;
            case "forgetEmail":
                valid = value.match(CONSTANTS.REGEX.EMAIL);
                !valid && (message = CONSTANTS.MESSAGES.VALIDATION.EMAIL);
                break;
            default:
                break;
        }
        var obj = {};

        this.setState(obj, () => {
            fieldName == "forgetEmail"
                ? this.validateForgotForm()
                : this.validateForm();
        });
    }
    validateForm() {
        this.setState({
            isFormValid: this.state.email.isValid && this.state.password.isValid
        });
    }

    //*****************************Forgot Password Validation*******************
    validateForgotForm() {
        this.setState({
            isForgotValid: this.state.forgetEmail.isValid
        });
    }
    onForgotPassword = () => {
        this.setState({
            loginForm: false,
            forgotForm: true,
            showTopBar: false,
            animated: true
        });
    };

    async adminLoginWebServices() {
        this.setState({ isLoading: true })
        try {
            let obj = {
                sEmailId: this.state.email.value.toLowerCase(),
                sPassword: this.state.password.value,
                sDeviceTokenWeb: this.state.server_key
            };
            let response = await WebService.adminLogin(obj);
            if (response.status) {
                this.setState({ isLoading: false, toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: false })
                this.handleSuccessResponse(response.data);

                // console.log("SUCCESS::" + response.status);
            } else {
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: '' })
                // console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }
    handleSuccessResponse(response) {
        setStoredData("adminId", response._id)
        setStoredData("adminEmailId", response.sEmailId)
        setStoredData("adminPhoneNumber", response.sPhoneNumber)
        setStoredData("adminFirstName", response.sUserFirstName)
        setStoredData("adminLastName", response.sUserLastName)
        setStoredData("adminProfile", response.sProfilePhoto)
        setTimeout(() => {
            this.props.history.push('/admindashboard')
        }, 3000);



    }
    render() {
        let { disabled, toaster, isToastShow, errorMessage, isWarning, isLoading } = this.state
        return (
            <div className='main-AdminLogin'>
                {toaster && <ToastComponent isWarning={isWarning} message={errorMessage} show={isToastShow} onClose={() => { this.setState({ toaster: false }); }} delay={3000} autohide ></ToastComponent>}
                <div className='inner-AdminLogin'>
                    <Scrollbars className='home-Scroll'>
                        <div className='main-Container'>
                            <div className='img-upload'>
                                <img src={require('../../../assets/images/loginicon.png')} className='profileimg' />
                            </div>
                            <h4>Login</h4>
                            <div className='form'>
                                <TextField title="Email"
                                    autoFocus={true}
                                    value={this.state.email.value}
                                    name="email"
                                    iconClass={"icon-mail"}
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.email.message}
                                    placeholder="johndoe@gmail.com" ></TextField>
                                <TextField title="Password" value={this.state.password.value}
                                    name="password"
                                    type={this.state.showPass ? "text" : "password"}
                                    iconClass={
                                        this.state.showEye
                                            ? this.state.showPass
                                                ? "icon-eye"
                                                : "icon-eyeoff"
                                            : "icon-eyeoff"
                                    }
                                    onKeyDown={this.onKeyDown.bind(this)}
                                    onClick={() =>
                                        this.setState({ showPass: !this.state.showPass })
                                    }
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.password.message}
                                    placeholder="******" ></TextField>
                                <Button
                                    loading={isLoading}
                                    title="Login"
                                    disabled={!this.state.isFormValid} onClick={() => this.onSubmit()}></Button>
                                <a href='/forgotpassword' className='aLink'>Forgot password ?</a>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default AdminLogin;

