export default async function dataconvert(amData, pmData) {
  if (amData && amData.length > 0 && pmData && pmData.length > 0) {
    // Convert the 'total' values to numbers using optional chaining
    amData[0].y = Number(amData[0]?.y);
    pmData[0].y = Number(pmData[0]?.y);

    // Log the modified amData for verification
    console.log("Modified amData:", amData);

    // Return both amData and pmData
    return { amData, pmData };
  } else {
    // Handle the case where amData or pmData is empty or undefined
    console.error("amData or pmData is empty or undefined");

    // Return undefined to indicate an error or empty data
    return undefined;
  }
}
