import React, { Component } from 'react';
import { setLocalActiveClass, getStoredData, setStoredData } from "../../../utils/helper";
import './profile.scss';
import { Scrollbars } from "react-custom-scrollbars";
import HomeSideMenu from '../../../components/homesidebar/homesidebar';
import HomeHeader from '../../../components/homeheader/homeheader';
import { Row, Col } from 'react-bootstrap';
import TextField from '../../../components/textfield/textfield';
import Button from "../../../components/Button/button";
import CONSTANTS from '../../../utils/constants';
import ToastComponent from "../../../components/toastcomponent/toastcomponent";
import { getFormDataObject } from '../../../utils/helper';
import WebService from "../../../utils/Webservice";
import AdminSideMenu from '../../../components/adminsidebar/adminsidebar';
import AdminHeader from '../../../components/adminheader/adminheader';
import userProfile from "../../../assets/images/profile.png";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profession: '',
            _id: (this.props.history.location.state.isViewFrom === 'adminProfile') ? getStoredData("userId") : getStoredData("_id"),
            isViewFrom: this.props.history.location.state.isViewFrom,
            imagePreviewUrl: '',
            isEdit: false,
            email: {
                value: "",
                isValid: true,
                message: ""
            },
            sFullName: {
                value: "",
                isValid: true,
                message: ""
            },
            sPhoneNumber: {
                value: "",
                isValid: true,
                message: ""
            },
            errorMessage: '',
            isToastShow: false,
            showPass: false,
            fileStatus: getStoredData("sProfilePhoto") !== 'undefined',
            successData: false,
            message: '',
        };
        setLocalActiveClass("HomeProfile");
    }

    componentDidMount() {
        this.getUserWebService();
    }

    async getUserWebService() {
        try {
            const obj = {
                _id: this.state._id,
            };
            const response = await WebService.getUserDetails(obj);
            if (response.status) {
                console.log("Success::" + response.status);
                this.handleSuccessResponse(response);
            } else {
                this.handleErrorResponse(response);
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSuccessResponse(response) {
        const { sUserFirstName, sUserLastName, sPhoneNumber, sEmailId, sProfilePhoto } = response.data;
        const fullName = this.state.sFullName;
        fullName.value = sUserFirstName + " " + sUserLastName;
        fullName.isValid = true;
        fullName.message = '';
        const phoneNumber = this.state.sPhoneNumber;
        phoneNumber.value = sPhoneNumber;
        phoneNumber.isValid = true;
        phoneNumber.message = '';
        const emailId = this.state.email;
        emailId.value = sEmailId;
        emailId.isValid = true;
        emailId.message = '';
        this.setState({
            sFullName: fullName,
            sPhoneNumber: phoneNumber,
            email: emailId,
            imagePreviewUrl: sProfilePhoto,
        });
    }

    onEdit() {
        this.setState({
            isEdit: true,
            isFormValid: this.state.email.isValid && this.state.sPhoneNumber.isValid && this.state.sFullName.isValid
        });
    }

    _handleImageChange(e) {
        if (e.target.files.length <= 0) {
            return;
        }
        const allowedExtensions = [".jpg", ".png", ".jpeg"];
        const file = e.target.files[0];
        const fileExtension = file.name.toLowerCase();
        const hasValidExtension = allowedExtensions.some(extension => fileExtension.endsWith(extension));

        if (!hasValidExtension) {
            this.setState({
                fileToaster: true,
                message: CONSTANTS.PHOTOS.JPGALLOW,
                isToastShow: true,
                isWarning: true
            });
            return;
        }

        const fsize = file.size;
        const fileSize = Math.round(fsize / 1024);
        const reader = new FileReader();
        reader.onloadend = () => {
            this.setState({
                fileStatus: this.state.imagePreviewUrl !== '' && e.target.files.length !== 0,
                file: file,
                imagePreviewUrl: reader.result
            });
        };
        reader.readAsDataURL(file);
    }


    handleUserInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        const obj = {};
        obj[name] = {
            value: value
        };
        this.setState(obj, () => {
            this.validateField(name, value);
        });
    }

    validateField(fieldName, value) {
        let valid = false;
        let message = "";

        switch (fieldName) {
            case "email":
                valid = value.match(CONSTANTS.REGEX.EMAIL) && value.length >= 0;
                if (!valid) {
                    message = value.length <= 0 ? CONSTANTS.MESSAGES.VALIDATION.EMAIL_LENGTH : CONSTANTS.MESSAGES.VALIDATION.EMAIL;
                }
                break;
            case "sFullName":
                valid = value.length > 0;
                if (!valid) {
                    message = CONSTANTS.MESSAGES.VALIDATION.FULNAME;
                }
                break;
            case "sPhoneNumber":
                valid = value.match(CONSTANTS.REGEX.PHONE_NUMBER) && value.length >= 0;
                if (!valid) {
                    message = value.length <= 0 ? CONSTANTS.MESSAGES.VALIDATION.PHONE_REQ : CONSTANTS.MESSAGES.VALIDATION.PHONE;
                }
                break;
            default:
                break;
        }

        const obj = {};
        obj[fieldName] = {
            message: message,
            isValid: valid,
            value: value
        };
        this.setState(obj, () => {
            this.validateForm();
        });
    }

    validateForm() {
        this.setState({
            isFormValid: this.state.email.isValid && this.state.sPhoneNumber.isValid && this.state.sFullName.isValid
        });
    }

    onSubmit() {
        this.validateField('email', this.state.email.value);
        this.validateField('sFullName', this.state.sFullName.value);
        this.validateField('sPhoneNumber', this.state.sPhoneNumber.value);
        this.userProfileEditWebServices();
    }

    async userProfileEditWebServices() {
        const fullName = this.state.sFullName.value.includes(" ") ? this.state.sFullName.value.split(" ") : this.state.sFullName.value;

        try {
            const obj = {
                _id: this.state._id,
                sUserFirstName: typeof fullName === 'string' ? fullName : (fullName[0] === undefined ? fullName : fullName[0]),
                sUserLastName: typeof fullName === 'string' ? '' : (fullName[1] === undefined ? fullName : fullName[1]),
                sEmailId: this.state.email.value,
                sPhoneNumber: this.state.sPhoneNumber.value,
                sProfilePhoto: this.state.file
            };
            const data = getFormDataObject(obj);

            const response = await WebService.userProfileUpdate(data);

            if (response.status) {
                this.setState({
                    toaster: true,
                    errorMessage: response.MESSAGES,
                    isToastShow: true,
                    isWarning: false
                });
                this.handleSuccessUserProfileUpdate(response.data);
                console.log("SUCCESS::" + response.status);
            } else {
                this.setState({ toaster: true, errorMessage: response.MESSAGES, isToastShow: true, isWarning: true })
                console.log("Error::" + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    handleSuccessUserProfileUpdate(response) {
        setTimeout(() => {
            this.setState({
                isEdit: "false",
            })
            window.location.reload(false);
        }, 3000)

    }
    render() {
        let { profession, treatment, isViewFrom, adminEmail, adminPhoneNumber, imagePreviewUrl, fileToaster, sucessData, adminFirstName, adminLastName, errorMessage, isToastShow, file, toaster, message, isShow, isWarning } = this.state

        return (
            <div className='main-Profile'>
                {isViewFrom === 'adminProfile' && <AdminHeader  {...this.props} ></AdminHeader>}
                {isViewFrom === 'home' ? <HomeSideMenu {...this.props} showMorePopup={true}></HomeSideMenu> : <AdminSideMenu {...this.props} showMorePopup={true}></AdminSideMenu>}

                {isViewFrom != 'adminProfile' && <HomeHeader  {...this.props} ></HomeHeader>}


                <div className='inner-Profile'>
                    <Scrollbars className='home-Scroll'>
                        {toaster && <ToastComponent isWarning={isWarning} message={errorMessage} show={isToastShow} onClose={() => { this.setState({ toaster: false }); }} delay={3000} autohide ></ToastComponent>}
                        {fileToaster && <ToastComponent isWarning={isWarning} message={message} show={isToastShow} onClose={() => { this.setState({ fileToaster: false }); }} delay={5000} autohide ></ToastComponent>}

                        <div className='main-Container'>
                            {isViewFrom !== 'adminProfile' && <div className="tooltip_main">
                                <div className={this.state.infoView ? 'divclass show ' : 'divclass'} onClick={() => this.setState({ infoView: !this.state.infoView })}></div>
                                <div className={this.state.infoView ? 'tool-Tips show' : 'tool-Tips'} >
                                    <a class="tip icon-info cursor" onClick={() => this.setState({ infoView: !this.state.infoView })}>
                                        <span className="info-detail">You can change your details here, look at FAQs, add your thumbnail and please leave us feedback!                            </span>
                                    </a>
                                </div>
                            </div>
                            }
                            <div className='img-upload'>
                                <img
                                    onChange={e => { this._handleImageChange(e); e.target.value = null }} src={imagePreviewUrl == undefined ? userProfile : imagePreviewUrl}
                                    className='profileimg' />
                                {this.state.isEdit !== "false" && <div class="img-edit">
                                    <label for="file-input">
                                        <span className='icon-camera cameraclass' />
                                    </label>
                                    <input id="file-input" accept="image/*" type="file" onChange={e => { this._handleImageChange(e); e.target.value = null }} />

                                </div>}
                            </div>
                            <div className='form'>
                                <TextField
                                    title="Full Name"
                                    name="sFullName"
                                    autoFocus={this.state.isEdit === "true" && true}
                                    placeholder={this.state.sFullName === undefined || this.state.isEdit == "false" ? 'john doe' : this.state.sFullName.value}
                                    readOnly={this.state.isEdit == "false" ? "readOnly" : false}
                                    value={this.state.sFullName.value}
                                    onChange={this.handleUserInput.bind(this)}
                                    errorMessage={this.state.sFullName.message}
                                ></TextField>
                                <TextField
                                    title="Email"
                                    name="email"
                                    readOnly={this.state.isEdit == "false" ? "readOnly" : false}
                                    value={this.state.email.value}
                                    errorMessage={this.state.email.message}
                                    onChange={this.handleUserInput.bind(this)}
                                    placeholder={this.state.email.value === undefined || this.state.isEdit == "false" ? 'johndoe@gmail.com' : this.state.email.value}
                                ></TextField>
                                <TextField
                                    title="Phone"
                                    name="sPhoneNumber"
                                    errorMessage={this.state.sPhoneNumber.message}
                                    readOnly={this.state.isEdit == "false" ? "readOnly" : false}
                                    onChange={this.handleUserInput.bind(this)}
                                    placeholder={this.state.sUserPhoneNumber === undefined || this.state.isEdit == "false" ? '+401-44-668-0938' : this.state.sUserPhoneNumber.value}
                                    value={this.state.sPhoneNumber.value}
                                ></TextField>
                            </div>
                            {this.state.isViewFrom !== 'adminProfile' &&
                                (this.state.isEdit == "false" ?
                                    <a onClick={() => { this.onEdit() }} className='aLink'>Edit</a> :
                                    <Button title="save" disabled={!this.state.isFormValid} onClick={() => this.onSubmit()}></Button>)
                            }
                        </div>
                    </Scrollbars>
                </div>
            </div>
        )
    }
}




export default Profile;

