import React, { Component } from "react";
import {} from "react-bootstrap";
import "./Highchart.scss";
import logo from "../../assets/images/logo.svg";
import Loader from "react-spinner-loader";
import dataconvert from "../../utils/dataconvert";
const Highcharts = require("react-highcharts");
var ReactHighstock = require("react-highcharts/ReactHighstock.src");
var img_path = "https://www.highcharts.com/samples/graphics/sun.png";
class Highchart extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true,
      type: "line",
    };
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.graphDate?.length ||
      newProps.graphTouchTestDate?.length ||
      !newProps.graphDate?.length ||
      !newProps.graphTouchTestDate?.length
    ) {
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: true,
      });
    }
  }
  render() {
    let { amData, pmData, graphDate, graphTouchTestDate, graphTouchTestData } =
      this.props;
    // const chartVal = Number(amData[0].total);
    // Define an async function to use await

    
    console.log("/////*******", amData);
    console.log("@@@@@@@@@@@@", pmData);
    pmData &&
      pmData.map((res) => {
        res.y = Number(res.y);
      });
      amData &&
      amData.map((res) => {
        res.y = Number(res.y);
      });
    async function processData() {
      try {
        let conversion = await dataconvert(amData, pmData);

        // Check if data conversion was successful
        if (conversion) {
          // Access modified amData and pmData
          let { amData, pmData } = conversion;

          // Use the modified data as needed
          console.log("amData:", amData);
          console.log("pmData:", pmData);
        } else {
          // Handle the case where data conversion failed
          console.error("Data conversion failed.");
        }
      } catch (error) {
        // Handle any errors that might occur during the asynchronous operation
        console.error("Error processing data:", error);
      }
    }

    // Call the async function
    processData();

    let dataSeries = [];
    let graphDateData = [];
    let title = "";
    if (graphTouchTestData === undefined) {
      console.log(amData, pmData, "check thiiiiiiiisisiisis");
      title = "In Minutes";
      graphDateData = graphDate;
      dataSeries = [
        {
          name: "AM",
          data: amData,
        },
        {
          name: "PM",
          data: pmData,
        },
      ];
    } else {
      title = "No. of Touches";
      graphDateData = graphTouchTestDate;
      dataSeries = [
        {
          name: "TouchTest",
          data: graphTouchTestData,
        },
      ];
    }
    const chartConfig = {
      chart: {
        renderTo: "highchartcontainer",
        type: "column",
        height: 400,
        reflow: true,
        // zoomType: 'x',
        panning: true,
        // inverted: true,
        // events: {
        //     load: function () {
        //         this.xAxis[0].setExtremes(0, 5);
        //     }
        // }
      },
      title: {
        text: null,
      },
      scrollbar: {
        barBackgroundColor: "#3bb6a6",
        barBorderRadius: 7,
        barBorderWidth: 0,
        buttonBackgroundColor: "#3bb6a6",
        buttonBorderWidth: 0,
        buttonBorderRadius: 7,
        trackBackgroundColor: "none",
        trackBorderWidth: 1,
        trackBorderRadius: 8,
        trackBorderColor: "#3bb6a6",
      },
      navigator: {
        enabled: false,
      },
      subtitle: {
        text: null,
      },
      rangeSelector: {
        buttonSpacing: 10,
        enabled: false,
      },
      xAxis: {
        type: "category",
        gridLineWidth: 0,
        lineColor: "#e6e6e6",
        // minorTickLength: 0,
        tickLength: 1,
        tickmarkPlacement: "on",
        categories: graphDateData,
        crosshair: SVGComponentTransferFunctionElement,
        // min: 0,
        // max: max - 1,

        // scrollbar: {
        //     enabled: true
        // }
      },
      scrollbar: {
        enabled: true,
        liveRedraw: false,
      },
      yAxis: {
        min: 0,
        title: {
          text: title,
        },
        stackLabels: {
          enabled: true,
          style: { fontWeight: "bold" },
        },
        lineColor: "#e6e6e6",
        minorTickLength: 0,
        tickLength: 0,
        lineWidth: 1,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0,
          dataLabels: {
            useHTML: true,
            enabled: true,
            style: {
              fontSize: "10px",
              color: "contrast",
              textOutline: null,
            },
            formatter: function () {
              if (this.point.y === 0) {
                // debugger
                if (this.point.isOveruse === true) {
                  if (this.point.isPhotos) {
                    return (
                      'span className="icon-camera icon-css"></span><span className="icon-warning icon-css"></span></br><span style="display:table; margin: 0 auto;">' +
                      this.y +
                      "</span>"
                    );
                  } else {
                    return (
                      '<span className="icon-warning icon-css"></span></br><span style="display:table; margin: 0 auto;">' +
                      this.y +
                      "</span>"
                    );
                  }
                } else {
                  if (this.point.isPhotos) {
                    return (
                      '<span className="icon-camera icon-css"></span></br><span style="display:table; margin: 0 auto;">' +
                      this.y +
                      "</span>"
                    );
                  } else {
                    return '<span style="display:table; margin: 0 auto;"></span>';
                  }
                }
                // return '';
              } else {
                if (this.point.isOveruse === true) {
                  if (this.point.isPhotos) {
                    return (
                      '<span className="icon-camera icon-css"></span><span className="icon-warning icon-css"></span></br><span style="display:table; margin: 0 auto;">' +
                      this.y +
                      "</span>"
                    );
                  } else {
                    return (
                      '<span className="icon-warning icon-css"></span></br><span style="display:table; margin: 0 auto;">' +
                      this.y +
                      "</span>"
                    );
                  }
                } else {
                  if (this.point.isPhotos) {
                    return (
                      '<span className="icon-camera icon-css"></span></br><span style="display:table; margin: 0 auto;">' +
                      this.y +
                      "</span>"
                    );
                  } else {
                    return (
                      '<span style="display:table; margin: 0 auto;">' +
                      this.y +
                      "</span>"
                    );
                  }
                }
              }
            },
          },
          series: {
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
      },
      colors: ["#A77CA8", "#590459"],
      tooltip: {
        enabled: false,
      },
      series: dataSeries,
    };
    return (
      <div id="newhighchartcontainer">
        <Loader
          id="cover-spin"
          className="isLoading"
          show={this.state.isLoading}
          type="box"
          message="Please wait while data is loading..."
        />
        <Highcharts
          isPureConfig={true}
          config={chartConfig}
          ref="chart"
        ></Highcharts>
      </div>
    );
  }
}
export default Highchart;
